import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFirebase } from 'react-redux-firebase'
import {
  Snackbar,
  LinearProgress,
  makeStyles,
  createStyles,
  Theme
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'

const phoneNumberRegex = /^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    loader: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2)
      }
    }
  })
)
const Whatsapp = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  const firebase = useFirebase()
  const [number, setNumber] = useState('')
  const [hasError, setHasError] = useState(true)
  const [loading, setLoading] = useState(false)
  const [sendSuccesfull, setSendSuccesfull] = useState(false)
  const [sendFailed, setSendFailed] = useState(false)

  const sendWhatsapp = async () => {
    console.log(1)
    if (hasError) return
    setLoading(true)
    const whatsappCloudFunction = (firebase as any)
      .functions()
      .httpsCallable('defaults-sendFirstWhatsapp')
    try {
      const result = await whatsappCloudFunction({ number })
      if (result.data.success) {
        setSendSuccesfull(true)

        return
      }
      setSendFailed(true)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
    setSendFailed(true)
  }
  return (
    <div className={classes.root}>
      {t('whatsapp.sendWelcomeMessage')}
      <TextField
        autoFocus
        fullWidth
        margin="dense"
        value={number}
        error={hasError}
        helperText={hasError ? t('messages.phoneIncorrect') : undefined}
        onChange={(event) => {
          setHasError(!phoneNumberRegex.test(event.target.value))
          setNumber(event.target.value)
        }}
        label={`*${t('phoneNumber')}`}
      />
      {loading && (
        <div className={classes.loader}>
          <LinearProgress />
        </div>
      )}
      <Button color="primary" onClick={sendWhatsapp}>
        {t('buttons.send')}
      </Button>

      <Snackbar
        autoHideDuration={2000}
        open={sendSuccesfull}
        onClose={() => setSendSuccesfull(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="success">{t('whatsapp.sendSuccesfull')}</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={2000}
        open={sendFailed}
        onClose={() => setSendFailed(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="error">{t('whatsapp.sendFailed')}</Alert>
      </Snackbar>
    </div>
  )
}

export default Whatsapp
