import { ParseResult } from 'papaparse'
import {
  MerchantRelationShipForSupplier,
  Supplier,
  Product
} from '@hierfoods/interfaces'
import { ExtendedFirebaseInstance } from 'react-redux-firebase'

export type CSV = ParseResult<any>

export enum DBFields {
  HIER_NUMBER = 'id',
  NUMBER = 'supplier_product_id',
  TITLE = 'title',
  //  PRICE = 'price',
  BULK_PRICE = 'bulk_price',
  BULK_AMOUNT = 'bulk_amount',
  EAN = 'ean',
  CATEGORY = 'category_id',
  SUPPLIER_CATEGORY = 'supplier_category_id',
  BULK_UNIT = 'bulk_unit_id',
  SINGLE_UNIT = 'single_unit_id',
  PRODUCT_TYPE = 'product_type_id',
  VAT = 'vat_id',
  WEIGHT = 'weight'
}

export type DBFieldsConfiguration = {
  [key in DBFields]?: {
    required?: boolean
    reader?: (value: string) => any
    connectionLookupField?: string
    connectionName?: string
  }
}
export type FieldMapping = {
  [key in DBFields]?: string
}

export interface FileMapping {
  // options
  fields: FieldMapping
  deleteUnmatched: boolean
  preProcessingStratergy?: string
  fieldsConfiguration?: DBFieldsConfiguration
  encoding?: string
  // data required from firebase
  supplier: Supplier
  supplierMerchantsRelationships: MerchantRelationShipForSupplier[]
  connections: {
    [key in DBFields]?: {
      items: Array<{
        key: string
        value?: string | number
        title?: string | number
      }>
      refName?: string
      propGenerator?: (
        partialItem: Record<string, any>,
        existingRecords: Record<string, any>[]
      ) => Record<string, any>
    }
  }
}

export interface CSVProductDiff {
  deleted: Product[]
  updated: ProcessedRecord[]
  created: ProcessedRecord[]
}
export interface ProcessedRecord {
  mapped: Product
  original: Record<string, any>
}

export interface CSVTemplate {
  name: string
  encoding?: string
  onlyUpdatePrices?: true
  disableReMapping?: boolean
  validateSheet?: (rows: Record<string, any>[]) => Promise<boolean>
  preProcessors: {
    sheet?: (
      rows: Record<string, any>[],
      mapping: FileMapping
    ) => Promise<Record<string, any>[]>
    row?: (
      row: Record<string, any>,
      index: number,
      list: Record<string, any>[]
    ) => Record<string, any>
  }
  fieldsMapping: Record<string, any>
  fieldsConfiguration: DBFieldsConfiguration
  databaseHook?: (
    firebase: ExtendedFirebaseInstance,
    mapping: FileMapping,
    record: Record<string, any>,
    originalRow: Record<string, string>,
    action: 'create' | 'update' | 'delete'
  ) => Promise<any>
}
