/* eslint-disable react-hooks/exhaustive-deps */
import {
  Order,
  OrderMeta,
  OrderProduct,
  OrderProductDetails,
  RevisionedOrder
} from '@hierfoods/interfaces'
import {
  Avatar,
  CircularProgress,
  createStyles,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  makeStyles,
  Radio,
  Snackbar,
  Switch,
  TextField,
  Theme,
  Tooltip
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import LinearProgress from '@material-ui/core/LinearProgress'
import converter from 'json-2-csv'
import { orderBy } from 'lodash'
import MaterialTable from '@material-table/core'
import moment from 'moment'
import React, { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  ExtendedFirebaseInstance,
  useFirebase,
  useFirebaseConnect
} from 'react-redux-firebase'
import {
  ensureInt,
  makeTimestampToMoment,
  mapToArrayWithID,
  useDeliveryIcons,
  useSearchQuery
} from '../../services/helpers'
import { useDialog } from 'use-material-ui-dialog'

import MerchantListDropdown from '../merchantListDropdown'

interface OrderDetails {
  orderProducts: (OrderProduct & { calculatedPrice: number })[]
  fullPrice: number
  allProducts: { [key: string]: OrderProductDetails }
}
const defaultDeliveryStatusStyle = { fontWeight: 'bold' as 'bold', margin: 2 }
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    loader: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2)
      }
    },
    orderPrice: {
      fontWeight: 'bold'
    },
    tableContainer: {
      maxWidth: '95vw'
    },
    /* ORDER STATUS */

    draft: { backgroundColor: '#ff9800', fontWeight: 'bold' },
    rejected: { backgroundColor: '#d32f2f', fontWeight: 'bold' },
    delivered: { backgroundColor: '#388e3c', fontWeight: 'bold' },
    sent: { backgroundColor: '#4caf50', fontWeight: 'bold' },
    confirmed: { backgroundColor: '#4caf50', fontWeight: 'bold' },

    /* DELIVERY STATUS */
    accepted: {
      backgroundColor: '#2196f3',
      ...defaultDeliveryStatusStyle
    },
    queued: { backgroundColor: '#2196f3' },
    sending: { backgroundColor: '#2196f3', ...defaultDeliveryStatusStyle },
    failed: { backgroundColor: '#d32f2f', ...defaultDeliveryStatusStyle },
    read: { backgroundColor: '#388e3c', ...defaultDeliveryStatusStyle },
    success: { backgroundColor: '#388e3c', ...defaultDeliveryStatusStyle },
    /* FaxDeStatus */
    OK: { backgroundColor: '#388e3c', ...defaultDeliveryStatusStyle },
    BUSY: { backgroundColor: '#d32f2f', ...defaultDeliveryStatusStyle },
    NOANSWER: { backgroundColor: '#d32f2f', ...defaultDeliveryStatusStyle },
    VOICE: { backgroundColor: '#d32f2f', ...defaultDeliveryStatusStyle },
    NORING: { backgroundColor: '#d32f2f', ...defaultDeliveryStatusStyle },
    ISDNERR: { backgroundColor: '#d32f2f', ...defaultDeliveryStatusStyle },
    NOSERVICE: { backgroundColor: '#d32f2f', ...defaultDeliveryStatusStyle },
    BLACKLIST: { backgroundColor: '#d32f2f', ...defaultDeliveryStatusStyle },

    undelivered: {
      backgroundColor: '#d32f2f',
      ...defaultDeliveryStatusStyle
    },
    receiving: {
      backgroundColor: '#ff9800',
      ...defaultDeliveryStatusStyle
    },
    received: {
      backgroundColor: '#ff9800',
      ...defaultDeliveryStatusStyle
    },
    processing: {
      backgroundColor: '#ff9800',
      ...defaultDeliveryStatusStyle
    },
    'no-answer': {
      backgroundColor: '#ff9800',
      ...defaultDeliveryStatusStyle
    },
    busy: { backgroundColor: '#ff9800', ...defaultDeliveryStatusStyle },
    canceled: {
      backgroundColor: '#ff9800',
      ...defaultDeliveryStatusStyle
    },
    default: { backgroundColor: 'gray', ...defaultDeliveryStatusStyle }
  })
)
const OrderDetailPanel = (order: Order) => {
  const firebase = useFirebase()
  const [details, setDetails] = useState<OrderDetails>()

  useEffect(() => {
    const getData = async () => {
      const details = await getFullDetailsForOrder(order, firebase)
      setDetails(details)
    }
    if (order) getData()
  }, [order.id])

  if (!details) return <CircularProgress />
  const makeToPriceString = (price: number): string =>
    (price / 100).toFixed(2).replace('.', ',')

  return (
    <List
      subheader={
        <ListSubheader component="div">
          {`${makeToPriceString(details.fullPrice)}€`}
        </ListSubheader>
      }>
      {details.orderProducts.map(orderProduct => {
        const titleToDisplay = (() => {
          if (orderProduct?.originalData?.productType)
            return orderProduct.originalData.productType
          if (orderProduct?.originalData?.name)
            return orderProduct.originalData.name
          if (details.allProducts?.[orderProduct.product_id]?.title)
            return details.allProducts[orderProduct.product_id].title
          return ''
        })()
        return (
          <>
            <Divider />
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <ShoppingCartIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`${orderProduct.amount} * ${titleToDisplay} | ${orderProduct.product_id}`}
                secondary={`${makeToPriceString(
                  orderProduct.calculatedPrice
                )}€`}
              />
            </ListItem>
          </>
        )
      })}
    </List>
  )
}

const renderDetailPanel = ({ rowData }) => (
  <OrderDetailPanel {...rowData} key={rowData.id} />
)

const OrderStatusChip = ({ status }: Order): ReactElement => {
  const classes = useStyles()

  if (!status) return null
  return <Chip className={classes[status]} label={status.toUpperCase()} />
}

const DeliveryStatusChips = ({ deliveryCallbacks }: Order): ReactElement => {
  const classes = useStyles()
  const { t } = useTranslation()
  const Icons = useDeliveryIcons()
  if (!deliveryCallbacks) return null
  const deliverCallbacksArray = Object.values(deliveryCallbacks)
  if (deliverCallbacksArray.length === 0) return null

  return (
    <div>
      {deliverCallbacksArray.map(deliveryCallback => (
        <Tooltip title={deliveryCallback.deliveryAddress}>
          <Chip
            icon={Icons[deliveryCallback?.deliveryType]}
            className={
              classes[deliveryCallback?.deliveryStatus] || classes.default
            }
            label={
              deliveryCallback?.deliveryStatus?.toUpperCase() ||
              t('order.noStatus')
            }
          />
        </Tooltip>
      ))}
    </div>
  )
}
const OrderList = ({
  path = 'orders',
  hideSendAgain,
  showExporter,
  limitQueryResults
}: {
  path: string
  hideSendAgain?: boolean
  showExporter?: boolean
  limitQueryResults?: boolean
}) => {
  const { t } = useTranslation()
  const firebase = useFirebase()
  const classes = useStyles()
  const dialog = useDialog()
  const [downloadingCsv, setDownloadingCsv] = useState(false)
  const [openExport, setOpenExport] = useState(false)
  const [combineCSVs, setCombineCSVs] = useState(false)
  const [moreActionsVisible, setMoreActionsVisible] = useState(false)
  const [exportMerchantsList, setExportMerchantsList] = useState<string[]>([])
  const [exportLoading, setExportLoading] = useState(false)
  const [exportFromDate, setExportFromDate] = useState<string | null>()
  const [exportToDate, setExportToDate] = useState<string | null>()
  const [exportProgress, setExportProgress] = useState(0)
  const [limitQuery, setLimitQuery] = useState(!!limitQueryResults)
  const suppliers = useSelector((s: any) => s?.firebase?.data?.suppliers)
  useFirebaseConnect([
    {
      path,
      queryParams: [
        'orderByChild=created_at',
        // Only load last 90 days by default
        ...(limitQuery
          ? [
              `endAt=${Date.now()}`,
              `startAt=${Date.now() - 1000 * 60 * 60 * 24 * 90}`
            ]
          : [])
      ]
    },
    { path: 'merchants' },
    { path: 'suppliers' },
    { path: 'order_users' }
  ])
  const [resendOrderID, setResendOrderID] = useState<string | undefined>()
  const orders = useSelector((state: any) => state.firebase.data[path])
  const merchants = useSelector((state: any) => state.firebase.data.merchants)
  const orderUsers = useSelector(
    (state: any) => state.firebase.data.order_users
  )

  const { onSearchChange, searchText } = useSearchQuery()

  const localization = {
    body: {
      emptyDataSourceMessage: t('messages.noData'),
      addTooltip: t('buttons.add'),
      deleteTooltip: t('buttons.delete'),
      editTooltip: t('buttons.edit'),
      editRow: {
        deleteText: t('messages.delete'),
        cancelTooltip: t('buttons.cancel'),
        saveTooltip: t('buttons.save')
      }
    },
    pagination: {
      labelDisplayedRows: t('pagination.label'),
      labelRowsSelect: t('pagination.rows'),
      labelRowsPerPage: t('pagination.perPage'),
      firstAriaLabel: t('pagination.first'),
      firstTooltip: t('pagination.first'),
      previousAriaLabel: t('pagination.previous'),
      previousTooltip: t('pagination.previous'),
      nextAriaLabel: t('pagination.next'),
      nextTooltip: t('pagination.next'),
      lastAriaLabel: t('pagination.last'),
      lastTooltip: t('pagination.last')
    },
    toolbar: {
      searchTooltip: t('buttons.search'),
      searchPlaceholder: t('buttons.search')
    },
    header: {
      actions: t('header.actions')
    }
  }
  const data: Order[] = orders
    ? Object.keys(orders).map((orderID: string) => {
        const getCreatedAt = () => {
          if (orders[orderID].created_at) {
            return makeTimestampToMoment(orders[orderID].created_at)
          }
          if (orders[orderID].updated_at) {
            return makeTimestampToMoment(orders[orderID].updated_at)
          }
          return undefined
        }
        const currentOrder = orders[orderID]
        return {
          ...currentOrder,
          id: orderID,
          merchant_name: merchants?.[currentOrder?.merchant_id]?.name || '',
          created_at: getCreatedAt(),
          order_user:
            orderUsers?.[currentOrder?.merchant_id]?.[currentOrder?.order_user]
              ?.title || ''
        }
      })
    : []
  const resendOrder = async () => {
    const resendOrderCloudFunction = (firebase as any)
      .functions()
      .httpsCallable('defaults-resendOrder')
    setResendOrderID(undefined)
    await resendOrderCloudFunction(resendOrderID)
  }

  const createCSVFromJsonAndDownload = (data, filename) => {
    return new Promise((resolve, reject) => {
      converter.json2csv(
        data,
        async (err, csv) => {
          if (err) {
            console.error(err)
            reject(err)
          } else {
            // Create a Link element with our CSV as download and click it
            const element = document.createElement('a')
            const file = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
            element.href = URL.createObjectURL(file)
            element.download = `${filename}.csv`
            document.body.appendChild(element)
            element.click()
            element.remove()
            resolve(true)
          }
        },
        {
          emptyFieldValue: ''
        }
      )
    })
  }

  const createMerchantCSVData = async (
    merchantID: string,
    download: boolean = true
  ) => {
    const fromTimestamp = moment(exportFromDate)
    const toTimestamp = moment(exportToDate).hours(23).minutes(59).seconds(59)
    const merchant_name = merchants?.[merchantID]?.name
    // Filter out all unneeded Orders
    const filtered = data.filter(
      o =>
        !o.is_test_order &&
        o.merchant_id === merchantID &&
        makeTimestampToMoment(o.preferred_delivery_date).isAfter(
          fromTimestamp
        ) &&
        makeTimestampToMoment(o.preferred_delivery_date).isBefore(
          toTimestamp
        ) &&
        (o.status === 'sent' ||
          o.status === 'delivered' ||
          o.status === 'confirmed')
    )
    /* Get the Total for Each Order. This takes a while cause we need to fetch data for each order */
    const extraData = {}
    for await (const order of filtered) {
      const details = await getFullDetailsForOrder(order, firebase)
      extraData[order.id] = {
        total: details.fullPrice,
        amount_of_order_products: details.orderProducts.length
      }
      details.orderProducts.forEach((op, index) => {
        extraData[order.id][`product_${index + 1}_name`] = `${
          op.originalData.productType || ''
        } ${op.originalData.name || ''}`.trim()
        extraData[order.id][`product_${index + 1}_unit_price`] =
          op.calculatedPrice / op.amount
        extraData[order.id][`product_${index + 1}_order_volume`] = op.amount
      })
    }
    // Final Order Data
    const output = filtered.map(f => ({
      order_date: makeTimestampToMoment(f.created_at).format(
        'DD.MM.YYYY, hh:mm'
      ),
      delivery_date: makeTimestampToMoment(f.preferred_delivery_date).format(
        'DD.MM.YYYY, hh:mm'
      ),
      order_products_string: f.order_products_string,
      supplier_Name: suppliers?.[f.supplier_id]?.name,
      supplier_id: f.supplier_id,
      merchant_name,
      merchant_id: merchantID,
      order_number: f.order_number,
      order_user: f.order_user,
      date_for_sorting: f.preferred_delivery_date,
      ...extraData[f.id]
    }))

    // Sort it
    output.sort((a, b) => a.date_for_sorting - b.date_for_sorting)
    if (download) {
      // Convert to CSV
      await createCSVFromJsonAndDownload(
        output,
        `${merchant_name}_${fromTimestamp.format(
          'DDMMYYYY'
        )}-${toTimestamp.format('DDMMYYYY')}`
      )
    } else return output
  }

  const downloadCSVs = async () => {
    setExportLoading(true)
    setExportProgress(0)
    let counter = 0
    let output = []
    for await (const merchantID of exportMerchantsList) {
      const data = await createMerchantCSVData(merchantID, !combineCSVs)
      output = output.concat(data)
      counter += 1
      setExportProgress((counter / exportMerchantsList.length) * 100)
    }
    if (combineCSVs) {
      await createCSVFromJsonAndDownload(
        output,
        `${moment(exportFromDate).format('DDMMYYYY')}-${moment(
          exportToDate
        ).format('DDMMYYYY')}`
      )
    }
    setExportLoading(false)
    setOpenExport(false)
  }
  const downloadOrderCSV = async (_event, rowData: Order) => {
    setDownloadingCsv(true)
    const fullDetails = await getFullDetailsForOrder(rowData, firebase)
    const data = [
      {
        Lieferant: rowData.supplier_name,
        Besteller: rowData.order_user,
        Bestellnummer: rowData.order_number,
        Bestelldatum: makeTimestampToMoment(rowData.created_at).format(
          'DD.MM.YYYY'
        ),
        Lieferdatum: makeTimestampToMoment(
          rowData.preferred_delivery_date
        ).format('DD.MM.YYYY'),
        Anzahl: '',
        Produktname: '',

        'Einzel-Preis': '',
        'Gesamt-Preis': '',
        Summe: ''
      },
      ...fullDetails.orderProducts.map(op => {
        const singlePrice =
          (op.originalData.bulkPrice ||
            (op.originalData.defaultPrice as number) ||
            0) / 100
        return {
          Lieferant: '',
          Besteller: '',
          Bestellnummer: '',
          Bestelldatum: '',
          Lieferdatum: '',
          Anzahl: op.amount.toString(),
          Produktname: `${op.originalData.productType || ''} ${
            op.originalData.name || ''
          }`.trim(),

          'Einzel-Preis': `${singlePrice.toFixed(2)}€`,
          'Gesamt-Preis': `${(op.calculatedPrice / 100).toFixed(2)}€`,
          Summe: ''
        }
      }),
      {
        Lieferant: '',
        Besteller: '',
        Bestellnummer: '',
        Bestelldatum: '',
        Lieferdatum: '',
        Anzahl: '',
        Produktname: '',
        'Einzel-Preis': '',
        'Gesamt-Preis': '',
        Summe: `${(fullDetails.fullPrice / 100).toFixed(2)}€`
      }
    ]

    await createCSVFromJsonAndDownload(
      data,
      `Bestellung #${rowData.order_number} vom ${makeTimestampToMoment(
        rowData.created_at
      ).format('DD.MM.YYYY')} ${
        // @ts-ignore
        rowData.merchant_name
      } - ${rowData.supplier_name}`
    )
    setDownloadingCsv(false)
  }
  const toggleTest = async (orderID: string, value: boolean) => {
    await firebase
      .database()
      .ref(`${path}/${orderID}`)
      .update({ is_test_order: value })
  }

  return (
    <div className={classes.root}>
      <Snackbar
        open={downloadingCsv}
        message="CSV wird erstellt..."
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />

      <Dialog
        open={!!resendOrderID}
        onClose={() => setResendOrderID(undefined)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {t('orderList.resendOrderPopup.title')}
          {resendOrderID}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('orderList.resendOrderPopup.description')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setResendOrderID(undefined)} color="primary">
            {t('orderList.resendOrderPopup.noButton')}
          </Button>
          <Button onClick={resendOrder} color="primary" autoFocus>
            {t('orderList.resendOrderPopup.yesButton')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog onClose={() => setOpenExport(false)} open={openExport}>
        <DialogTitle>{t('orderList.exportCsvPopup.title')}</DialogTitle>
        <List>
          <ListItem>
            <MerchantListDropdown
              selectedMerchants={exportMerchantsList}
              onChange={e => {
                setExportMerchantsList(e.target.value)
              }}
            />
          </ListItem>
          <ListItem>
            <TextField
              id="date"
              value={exportFromDate}
              onChange={e => setExportFromDate(e.target.value)}
              label={t('orderList.exportCsvPopup.fromDate')}
              type="date"
              InputLabelProps={{
                shrink: true
              }}
            />
          </ListItem>
          <ListItem>
            <TextField
              id="date"
              value={exportToDate}
              onChange={e => setExportToDate(e.target.value)}
              label={t('orderList.exportCsvPopup.toDate')}
              type="date"
              InputLabelProps={{
                shrink: true
              }}
            />
          </ListItem>
          <ListItem>{t('orderList.exportCsvPopup.combine')}</ListItem>
          <ListItem>
            <FormControlLabel
              control={
                <Radio
                  checked={combineCSVs}
                  onChange={() => setCombineCSVs(true)}
                />
              }
              label={t('orderList.exportCsvPopup.combineCSVFiles')}
            />
            <FormControlLabel
              control={
                <Radio
                  checked={!combineCSVs}
                  onChange={() => setCombineCSVs(false)}
                />
              }
              label={t('orderList.exportCsvPopup.singleCSVFiles')}
            />
          </ListItem>
          {exportLoading && (
            <ListItem>
              <LinearProgress
                variant="determinate"
                value={exportProgress}
                style={{ width: '100%' }}
              />
            </ListItem>
          )}
          <DialogActions>
            <Button onClick={() => setOpenExport(false)} color="primary">
              {t('buttons.cancel')}
            </Button>
            <Button
              onClick={downloadCSVs}
              color="primary"
              disabled={
                !exportFromDate ||
                !exportToDate ||
                exportMerchantsList.length === 0
              }>
              {exportLoading && <CircularProgress size={20} />}
              {t('buttons.download')}
            </Button>
          </DialogActions>
        </List>
      </Dialog>
      {limitQueryResults && (
        <>
          <p>
            <b>Zeige nur die letzten 90 Tage?</b>
            <Switch
              checked={limitQuery}
              onChange={(_, checked) => setLimitQuery(checked)}
              inputProps={{ 'aria-label': 'Switch A' }}
            />
          </p>
        </>
      )}

      <div className={classes.tableContainer}>
        <MaterialTable
          actions={[
            rowData => ({
              icon: rowData.is_test_order
                ? 'check_box'
                : 'check_box_outline_blank',
              tooltip: 'Testbestellung?',
              onClick: () => toggleTest(rowData.id, !rowData.is_test_order)
            }),
            {
              icon: 'link',
              tooltip: t('orderList.openOrder'),
              onClick: (event, rowData: Order) => {
                console.log(rowData)
                window.open(
                  `${process.env.REACT_APP_FIREBASE_HOSTING}/c/${rowData.short_code}`,
                  '_blank'
                )
              }
            },
            {
              icon: 'restore',
              hidden: hideSendAgain,
              tooltip: t('orderList.resendOrder.action'),
              onClick: (event, rowData: Order) => {
                setResendOrderID(rowData.id)
              }
            },
            {
              icon: 'save_alt',
              isFreeAction: true,
              hidden: !showExporter,
              tooltip: t('orderList.exportCsv.action'),
              onClick: (event, rowData) => {
                setOpenExport(true)
              }
            },
            {
              icon: 'offline_pin',
              hidden: !moreActionsVisible,
              tooltip: t('orderList.markAsSent'),
              onClick: async (_event, rowData: Order) => {
                if (window.confirm(t('orderList.confirmMarkAsFullySent')))
                  await firebase
                    .database()
                    .ref(`orders/${rowData.id}/all_notifications_sent`)
                    .set(true)
              }
            },

            {
              icon: 'data_object',
              hidden: !moreActionsVisible,
              tooltip: t('orderList.debug'),
              onClick: async (_event, rowData: Order) => {
                // These console.logs are here for debugging orders :)
                console.log(`order`, rowData)
                const orderMeta: OrderMeta =
                  (await (
                    await firebase
                      .database()
                      .ref(
                        `orders_meta/${rowData.merchant_id}/${rowData.supplier_id}/${rowData.id}`
                      )
                      .once('value')
                  ).val()) || {}
                console.log(`orderMeta`, orderMeta)
                const fullDetails = await getFullDetailsForOrder(
                  rowData,
                  firebase
                )
                console.log(`fullDetails`, fullDetails)
              }
            },
            {
              icon: 'download_for_offline',
              hidden: !moreActionsVisible,
              tooltip: t('orderList.exportCsv'),
              onClick: downloadOrderCSV
            },
            {
              icon: 'unfold_more',
              isFreeAction: true,
              tooltip: t('orderList.showAllActions'),
              onClick: () => {
                setMoreActionsVisible(v => !v)
              }
            }
          ]}
          columns={[
            {
              title: t('orderNumber'),
              field: 'order_number',
              // @ts-ignore
              width: 30,
              filtering: false
            },
            { title: 'ID', field: 'id' },
            {
              title: t('createdAt'),
              field: 'created_at',
              defaultSort: 'desc',
              customFilterAndSearch: (term, rowData) =>
                makeTimestampToMoment(rowData.created_at)
                  .format('DD.MM.YYYY HH:mm')
                  .includes(term),
              render: (order: Order) => (
                <span>
                  {makeTimestampToMoment(order.created_at).format('DD.MM.YYYY')}
                  <br />
                  {makeTimestampToMoment(order.created_at).format('HH:mm')}
                </span>
              )
            },
            { title: t('supplier'), field: 'supplier_name' },
            { title: t('merchant'), field: 'merchant_name' },
            { title: t('orderUser'), field: 'order_user' },
            {
              title: t('deliveryCallbacks'),
              field: 'deliveryCallbacks',
              render: (order: Order) => <DeliveryStatusChips {...order} />,
              filtering: false
            },
            {
              title: t('status'),
              field: 'status',
              render: (order: Order) => <OrderStatusChip {...order} />
            }
          ]}
          editable={{
            onRowDelete: (order: Order) =>
              new Promise(async resolve => {
                dialog.openConfirm({
                  title: '',
                  message: t('orderList.confirmDeleteNotification'),
                  okText: t('orderList.yesNotifyDeletedOrder'),
                  cancelText: t('orderList.noNotifyDeletedOrder'),
                  onOk: async () => {
                    await firebase.remove(`${path}/${order.id}`)
                    resolve(true)
                  },
                  onCancel: async () => {
                    const silentDeleteOrder = (firebase as any)
                      .functions()
                      .httpsCallable('defaults-silentDeleteOrder')
                    await silentDeleteOrder({ orderID: order.id })
                    resolve(true)
                  }
                })
              })
          }}
          data={data}
          detailPanel={renderDetailPanel}
          localization={localization}
          title={t('orders')}
          options={{
            pageSize: 50,
            pageSizeOptions: [10, 20, 50],
            searchText,
            actionsColumnIndex: 8,
            filtering: true,
            hideFilterIcons: true
          }}
          onSearchChange={onSearchChange}
        />
      </div>
    </div>
  )
}

const getPriceForOrderProduct = (
  orderProduct: OrderProduct & {
    orderMeta: OrderMeta
    revisionedProductDetails?: OrderProductDetails
    fallbackProduct?: OrderProductDetails
  }
) => {
  // use bulkPrice from order meta
  if (
    orderProduct?.orderMeta?.[orderProduct.product_id]?.bulk_price !== undefined
  ) {
    return (
      orderProduct.orderMeta[orderProduct.product_id].bulk_price *
      orderProduct.amount
    )
  }

  // If we have a defaultPrice and no bulkPrice (either 0 or undefined), we use the defaultPrice instead
  if (
    orderProduct?.originalData?.defaultPrice !== undefined &&
    !orderProduct?.originalData?.bulkPrice
  )
    return (
      ensureInt(orderProduct?.originalData?.defaultPrice) * orderProduct.amount
    )

  // If we have a revisioned bulk price and no originalData.bulkPrice (either 0 or undefined), we use the revisioned bulk price instead
  if (
    !orderProduct?.originalData?.bulkPrice &&
    orderProduct.revisionedProductDetails?.bulk_price &&
    ensureInt(orderProduct.revisionedProductDetails?.bulk_price) !== 0
  )
    return (
      ensureInt(orderProduct.revisionedProductDetails.bulk_price) *
      orderProduct.amount
    )

  // If we have a revisioned defaultPrice and no originalData.bulkPrice (either 0 or undefined), we use the revisioned bulk price instead
  if (
    !orderProduct?.originalData?.bulkPrice &&
    orderProduct?.revisionedProductDetails?.default_price &&
    ensureInt(orderProduct?.revisionedProductDetails?.default_price) !== 0
  )
    return (
      ensureInt(orderProduct.revisionedProductDetails.default_price) *
      orderProduct.amount
    )

  // No defaultprices no nothing, we use the bulkPrice if it wasnt undefined
  if (orderProduct?.originalData?.bulkPrice !== undefined)
    return (
      ensureInt(orderProduct?.originalData?.bulkPrice) * orderProduct.amount
    )

  // Now we're getting into REAL LEGACY STUFF
  if (
    orderProduct.revisionedProductDetails?.single_price &&
    ensureInt(orderProduct.revisionedProductDetails.single_price) !== 0
  )
    return (
      ensureInt(orderProduct.revisionedProductDetails.single_price) *
      ensureInt(orderProduct.revisionedProductDetails.bulk_amount) *
      orderProduct.amount
    )

  if (orderProduct?.fallbackProduct?.bulk_price !== undefined)
    return (
      ensureInt(orderProduct.fallbackProduct.bulk_price) * orderProduct.amount
    )
  return 0
}

const getFullDetailsForOrder = async (
  order: Order,
  firebase: ExtendedFirebaseInstance
): Promise<OrderDetails> => {
  const [orderProductsSnapshot, orderMeta] = await Promise.all([
    await (
      await firebase
        .database()
        .ref('order_products')
        .orderByChild('order_id')
        .equalTo(order.id)
        .once('value')
    ).val(),
    (await (
      await firebase
        .database()
        .ref(
          `orders_meta/${order.merchant_id}/${order.supplier_id}/${order.id}`
        )
        .once('value')
    ).val()) || {}
  ])
  const productsKeyValues = await (
    await firebase.database().ref(`products/${order.supplier_id}`).once('value')
  ).val()
  const orderProducts: OrderProduct[] = mapToArrayWithID(orderProductsSnapshot)

  let finalProducts = orderProducts
  if (
    orderProducts.some(
      o => !o.originalData || o.originalData.bulkPrice === undefined
    ) &&
    order.short_code &&
    order.status !== 'draft'
  ) {
    // Some products dont have originalData, so we check the order_revisions
    // This is just for legacy - Orders
    const revisioned = await (
      await firebase
        .database()
        .ref('revisioned_order')
        .orderByChild('short_code')
        .equalTo(order.short_code)
        .once('value')
    ).val()

    // There is a revisioned order
    if (revisioned) {
      const revisionsMapped = orderBy(
        Object.values(revisioned).map((r: any) => ({
          // Mapping ensures better sorting because null is treated different as 0 in sort
          ...r,
          updated_at: r.updated_at || 0,
          twilioFaxRetryCount: r.twilioFaxRetryCount || 0,
          created_at: r.created_at || 0
        })),
        ['updated_at', 'twilioFaxRetryCount', 'created_at'],
        ['desc', 'desc', 'desc']
      )
      const [lastOrder] = revisionsMapped as [RevisionedOrder]
      finalProducts = finalProducts.map(p => ({
        ...p,
        revisionedProductDetails: lastOrder.order_products.find(
          op => op.productDetails.id === p.product_id
        )?.productDetails
      }))
    }
  }
  const orderProductsWithPrice = finalProducts.map(op => ({
    ...op,
    calculatedPrice: getPriceForOrderProduct({
      ...op,
      orderMeta,
      fallbackProduct: productsKeyValues[op.product_id]
    })
  }))

  const fullPrice: number = orderProductsWithPrice.reduce(
    (previous: any, orderProduct: any) =>
      previous + orderProduct.calculatedPrice,
    0
  ) as number

  return {
    orderProducts: orderProductsWithPrice,
    fullPrice,
    allProducts: productsKeyValues
  }
}

export default OrderList
