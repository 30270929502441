import { ProductColums } from '@hierfoods/interfaces'
import Snackbar from '@material-ui/core/Snackbar'
import { makeStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import { selectMerchant } from '@selectors/merchant'
import { selectSupplier } from '@selectors/supplier'
import { selectProductTypes } from '@selectors/units'
import MaterialTable from 'material-table'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useFirebase, useFirebaseConnect } from 'react-redux-firebase'
import { selectProductsWithPricesForSupplierMerchant } from '../../selectors/product'
import {
  mapToArrayWithID,
  useSearchQuery,
  useTableRef
} from '../../services/helpers'
import BasicCsvImporter from '../basic-importer/BasicCsvImporter'
import MerchantDropdown from '../merchantDropdown'
import SupplierDropdown from '../supplierDropdown'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  headerLine: {
    flexDirection: 'row',
    flex: 1,
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    paddingBottom: 20
  },
  tableContainer: {
    maxWidth: '95vw'
  }
})

const PricesList = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const firebase = useFirebase()
  const [loading, setLoading] = useState(false)
  const [importOpen, setImportOpen] = useState(false)
  const [currentImport, setCurrentImport] = useState<null | {
    currentStep: number
    total: number
    currentObject: any
    errors: number
  }>()

  /* Fetch data */
  const supplier = useSelector(selectSupplier)
  const merchant = useSelector(selectMerchant)
  const tableRef = useTableRef([supplier?.id])
  const { onSearchChange, searchText } = useSearchQuery()
  useFirebaseConnect([
    {
      path: 'product_types'
    },
    {
      path: `products/${supplier?.id}`
    },
    {
      path: `prices/${supplier?.id}/merchants/${merchant?.id}`
    }
  ])

  const productsWithPrices = useSelector(
    selectProductsWithPricesForSupplierMerchant
  )

  const productTypesMapped = useSelector(selectProductTypes)

  /* Define columns */
  const columns = [
    {
      title: 'HIER Artikelnummer',
      field: 'id',
      editable: 'never'
    },
    {
      title: 'Artikelnummer',
      field: 'supplier_product_id',
      editable: 'never'
    },
    {
      title: 'Typ',
      field: 'product_type_id',
      lookup: productTypesMapped,
      editable: 'never'
    },

    {
      title: 'Titel',
      field: 'title',
      editable: 'never'
    },

    {
      title: 'Preis (für Alle)',
      field: 'bulk_price',
      type: 'numeric',
      editable: 'never'
    },
    {
      title: 'VK (für Alle)',
      field: 'selling_price',
      type: 'numeric',
      editable: 'never'
    },
    {
      title: 'Preis',
      field: 'merchant_bulk_price',
      type: 'numeric'
    },
    {
      title: 'VK',
      field: 'merchant_selling_price',
      type: 'numeric'
    }
  ]

  /* Define table options */
  const localization = {
    body: {
      emptyDataSourceMessage: t('messages.noData'),
      addTooltip: t('buttons.add'),
      deleteTooltip: t('buttons.delete'),
      editTooltip: t('buttons.edit'),
      editRow: {
        deleteText: t('messages.delete'),
        cancelTooltip: t('buttons.cancel'),
        saveTooltip: t('buttons.save')
      }
    },
    pagination: {
      labelDisplayedRows: t('pagination.label'),
      labelRowsSelect: t('pagination.rows'),
      labelRowsPerPage: t('pagination.perPage'),
      firstAriaLabel: t('pagination.first'),
      firstTooltip: t('pagination.first'),
      previousAriaLabel: t('pagination.previous'),
      previousTooltip: t('pagination.previous'),
      nextAriaLabel: t('pagination.next'),
      nextTooltip: t('pagination.next'),
      lastAriaLabel: t('pagination.last'),
      lastTooltip: t('pagination.last')
    },
    toolbar: {
      searchTooltip: t('buttons.search'),
      searchPlaceholder: t('buttons.search')
    }
  }

  const options = {
    pageSize: 10,
    pageSizeOptions: [10, 25, 50, 100],
    addRowPosition: 'first',
    draggable: true,
    exportButton: true,
    exportAllData: true,
    searchText
  }

  const onRowUpdate = (product: any) => {
    return new Promise<void>(async resolve => {
      let {
        merchant_bulk_price: bulk_price = null,
        merchant_selling_price: selling_price = null,
        id
      } = product
      if (isNaN(bulk_price)) bulk_price = null
      if (isNaN(selling_price)) selling_price = null
      if (!bulk_price && !selling_price) {
        await firebase
          .database()
          .ref(`prices/${supplier.id}/merchants/${merchant.id}/${id}`)
          .remove()
      } else
        await firebase
          .database()
          .ref(`prices/${supplier.id}/merchants/${merchant.id}/${id}`)
          .update({
            bulk_price,
            selling_price
          })
      resolve()
    })
  }

  const importCSV = async (json: Record<string, string>[]) => {
    try {
      const getValue = (obj: any, key: string) => {
        if (!obj[key]) return null
        if (typeof obj[key] === 'string' && obj[key].length === 0) return null
        return obj[key]
      }

      const allProductsForThisSupplier = await (
        await firebase.database().ref(`/products/${supplier.id}`).once('value')
      ).val()
      const allProductsForThisSupplierAsArray = mapToArrayWithID(
        allProductsForThisSupplier
      )

      const mapped: {
        supplier_product_id?: string
        ean?: string
        bulk_price?: string
        selling_price?: string
      }[] = json.map((j: any) => ({
        supplier_product_id: getValue(j, ProductColums.SupplierProductID),
        ean: getValue(j, ProductColums.EAN)
          ? getValue(j, ProductColums.EAN).toString()
          : null,
        bulk_price: getValue(j, ProductColums.BulkPrice),
        selling_price: getValue(j, ProductColums.SellingPrice)
      }))

      const updates: {
        id: string
        merchant_selling_price?: number
        merchant_bulk_price?: number
      }[] = []
      mapped.forEach(map => {
        let productID
        // If we have a internal ID - find the matching product-id
        if (map.supplier_product_id) {
          productID = allProductsForThisSupplierAsArray.find(
            p => p.supplier_product_id === map.supplier_product_id
          )?.id
        }
        // if we have a ean, find the matching product-id
        if (!productID && map.ean) {
          productID = allProductsForThisSupplierAsArray.find(
            p => p.ean === map.ean
          )?.id
        }
        // if we found a product-id, add it to our updates-list
        if (productID)
          updates.push({
            id: productID,
            merchant_selling_price: map.selling_price
              ? parseInt(map.selling_price)
              : null,
            merchant_bulk_price: map.bulk_price
              ? parseInt(map.bulk_price)
              : null
          })
      })
      if (updates.length === 0) {
        setCurrentImport(null)
        setImportOpen(false)
        alert(t('pricesList.noMatchingProductsFound'))
        return
      }
      for await (const update of updates) {
        setCurrentImport(c => ({
          currentStep: (c?.currentStep || 0) + 1,
          total: updates.length,
          currentObject: update,
          errors: c?.errors || 0
        }))
        await onRowUpdate(update)
      }
      setCurrentImport(null)
      setImportOpen(false)
    } catch (e) {
      console.log(e)
      setCurrentImport(null)
      setImportOpen(false)
      alert(t('pricesList.errorInCSVFile'))
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.headerLine}>
        <SupplierDropdown />

        <MerchantDropdown />
      </div>
      <BasicCsvImporter
        open={importOpen}
        onClose={() => setImportOpen(false)}
        onImport={importCSV}
        currentImport={currentImport}
      />
      {supplier && merchant && (
        <div className={classes.tableContainer}>
          <MaterialTable
            actions={[
              {
                icon: 'cloud_upload',
                isFreeAction: true,
                tooltip: t('pricesList.importCsv.action'),
                onClick: () => {
                  setImportOpen(true)
                }
              }
            ]}
            title={t('products')}
            columns={columns as any}
            data={productsWithPrices}
            localization={localization}
            options={options as any}
            isLoading={loading}
            editable={{
              onBulkUpdate: async changes => {
                setLoading(true)
                for await (const change of Object.values(changes)) {
                  onRowUpdate(change.newData)
                }
                setLoading(false)
              },

              onRowUpdate
            }}
            tableRef={tableRef}
            onSearchChange={onSearchChange}
          />
        </div>
      )}

      <Snackbar
        open={!supplier}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="warning">{t('messages.selectSupplier')}</Alert>
      </Snackbar>
    </div>
  )
}
export default PricesList
