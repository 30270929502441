import {
  createStyles,
  makeStyles,
  Theme,
  Chip,
  Select,
  MenuItem,
  Button
} from '@material-ui/core'
import { selectSupplierList } from '@selectors/supplier'
import MaterialTable from 'material-table'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useFirebaseConnect, useFirebase } from 'react-redux-firebase'
import { Supplier, PreferredTypes } from '@hierfoods/interfaces'
import AddIcon from '@material-ui/icons/Add'
import clone from 'lodash/clone'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import Input from '@material-ui/core/Input'
import { updateSupplier } from '@services/database'
import SupplierImage from '../images/supplierImage'
import Delete from '@material-ui/icons/Delete'
import { useHistory } from 'react-router-dom'
import { setSupplier } from '@redux/supplier'
import {
  makeTimestampToMoment,
  useDeliveryIcons,
  useSearchQuery
} from '../../services/helpers'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    loader: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2)
      }
    },
    orderPrice: {
      fontWeight: 'bold'
    },
    default: { backgroundColor: 'lightgray', fontWeight: 'bold', margin: 2 },
    preferredTypeEditRow: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 10
    },
    preferredTypeEditDeleteIcon: {
      color: 'black'
    },
    preferredTypeEditInput: {
      flex: 1
    },
    preferredTypeEditAddIcon: {},
    imageEditContainer: {
      width: 80,
      height: 80,
      border: '1px solid lightgray',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    imageEditDeleteIcon: {
      position: 'absolute',
      top: 0,
      right: 0
    },
    imageEditEditIcon: {
      position: 'absolute',
      top: 0,
      left: 0
    },
    imageEditAddIcon: {},
    tableContainer: {
      maxWidth: '95vw'
    }
  })
)
const PreferredType = ({
  type
}: {
  type: { [key in PreferredTypes]?: string }
}) => {
  const classes = useStyles()
  const Icons = useDeliveryIcons()
  if (!type) return null
  const [stringIdentifier] = Object.keys(type)
  const identifier = stringIdentifier as PreferredTypes
  const value = type[identifier]
  if (identifier === 'crawler')
    return (
      <Chip
        icon={Icons[identifier]}
        label={`${identifier}`}
        className={classes.default}
      />
    )
  return (
    <Chip
      icon={Icons[identifier]}
      label={`${identifier}: ${value}`}
      className={classes.default}
    />
  )
}
const PreferredTypeEdit = ({
  data,
  onChange
}: {
  data: Supplier['preferred_types'][]
  onChange: (data: Supplier['preferred_types'][]) => void
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const onValueChanged = (index: string, key: string, value: string) => {
    const clonedData = clone(data)
    clonedData[index][key] = value
    onChange(clonedData)
  }
  const onKeyChanged = (index: string, key: string) => {
    const clonedData = clone(data)
    const [oldKey] = Object.keys(clonedData[index])

    clonedData[index] = { [key]: clonedData[index][oldKey], [oldKey]: null }
    onChange(clonedData)
  }
  const deleteRow = (index: number) => {
    const clonedData = clone(data)
    clonedData.splice(index, 1)
    onChange(clonedData)
  }
  const addRow = () => {
    let clonedData = clone(data)
    if (!clonedData) clonedData = []
    clonedData.push({ ['phone' as PreferredTypes]: '+49' } as any)
    onChange(clonedData)
  }
  return (
    <div>
      {data?.map((keyValuePair, index) => {
        const [key] = Object.keys(keyValuePair)
        const value = keyValuePair[key]
        if (key === 'crawler') return <PreferredType type={{ [key]: key }} />
        return (
          <div className={classes.preferredTypeEditRow}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={key}
              onChange={(change: any) =>
                onKeyChanged(index.toString(), change.target.value as string)
              }>
              <MenuItem value={'whatsapp' as PreferredTypes}>
                {t('whatsappType')}
              </MenuItem>
              <MenuItem value={'email' as PreferredTypes}>
                {t('email')}
              </MenuItem>
              <MenuItem value={'csvemail' as PreferredTypes}>
                {t('csvemail')}
              </MenuItem>
              <MenuItem value={'fax' as PreferredTypes}>{t('fax')}</MenuItem>
              <MenuItem value={'phone' as PreferredTypes}>
                {t('phone')}
              </MenuItem>
            </Select>
            <Input
              className={classes.preferredTypeEditInput}
              id="standard-basic"
              type="text"
              value={value}
              onChange={e =>
                onValueChanged(
                  index.toString(),
                  key,
                  e.target.value && e.target.value.trim()
                )
              }
            />
            <DeleteIcon
              className={classes.preferredTypeEditDeleteIcon}
              onClick={() => deleteRow(index)}
            />
          </div>
        )
      })}
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        size="small"
        onClick={addRow}
        className={classes.preferredTypeEditAddIcon}>
        {t('buttons.add')}
      </Button>
    </div>
  )
}

const SupplierList = () => {
  const { t } = useTranslation()
  const firebase = useFirebase()
  const history = useHistory()
  const dispatch = useDispatch()
  const classes = useStyles()
  const localization = {
    body: {
      emptyDataSourceMessage: t('messages.noData'),
      addTooltip: t('buttons.add'),
      deleteTooltip: t('buttons.delete'),
      editTooltip: t('buttons.edit'),
      editRow: {
        deleteText: t('messages.delete'),
        cancelTooltip: t('buttons.cancel'),
        saveTooltip: t('buttons.save')
      }
    },
    pagination: {
      labelDisplayedRows: t('pagination.label'),
      labelRowsSelect: t('pagination.rows'),
      labelRowsPerPage: t('pagination.perPage'),
      firstAriaLabel: t('pagination.first'),
      firstTooltip: t('pagination.first'),
      previousAriaLabel: t('pagination.previous'),
      previousTooltip: t('pagination.previous'),
      nextAriaLabel: t('pagination.next'),
      nextTooltip: t('pagination.next'),
      lastAriaLabel: t('pagination.last'),
      lastTooltip: t('pagination.last')
    },
    toolbar: {
      searchTooltip: t('buttons.search'),
      searchPlaceholder: t('buttons.search')
    }
  }

  useFirebaseConnect(['suppliers'])
  const data = useSelector(selectSupplierList)
  const { onSearchChange, searchText } = useSearchQuery()

  /* 
  workaround for our switching period from preferred_type to preferred_typeS!
   */
  const convertedData = data.map((supplier: Supplier) => {
    const preferred_types = supplier.preferred_types || [
      {
        [supplier.preferred_type]:
          supplier.preferred_type === 'crawler'
            ? 'crawler'
            : supplier[supplier.preferred_type]
      }
    ]
    return { ...supplier, preferred_types }
  })

  return (
    <div className={classes.root}>
      <div className={classes.tableContainer}>
        <MaterialTable
          actions={[
            {
              icon: 'list_alt',
              tooltip: 'Go to Products',
              onClick: (event, rowData) => {
                dispatch(setSupplier(rowData as Supplier))
                history.push('/products')
              }
            }
          ]}
          editable={{
            onRowAdd: (supplier: Supplier) =>
              new Promise(async resolve => {
                const key = (firebase as any)
                  .database()
                  .ref()
                  .child('suppliers')
                  .push().key
                supplier.id = key
                supplier.created_at = Date.now()
                await updateSupplier(supplier, firebase)
                resolve(true)
              }),
            onRowUpdate: (supplier: Supplier) =>
              new Promise(async (resolve, reject) => {
                await updateSupplier(supplier, firebase)
                resolve(true)
              })
          }}
          columns={[
            {
              title: 'ID',
              field: 'id',
              editable: 'never'
            },
            { title: 'Gesperrt', field: 'locked', type: 'boolean' },
            {
              title: 'TestSupplier',
              field: 'is_test_user',
              type: 'boolean'
            },
            {
              title: 'Name.',
              field: 'name',
              // @ts-ignore
              width: 30
            },
            {
              title: 'Telefonnummer',
              field: 'phone'
            },
            {
              title: 'Letztes Update',
              field: 'updated_at',
              defaultSort: 'desc',
              render: (supplier: Supplier) =>
                supplier?.updated_at ? (
                  <span>
                    {makeTimestampToMoment(supplier.updated_at).format(
                      'DD.MM.YY'
                    )}
                    <br />
                    {makeTimestampToMoment(supplier.updated_at).format('HH:mm')}
                  </span>
                ) : (
                  '-'
                ),
              editable: 'never'
            },
            {
              title: 'Erstellt am',
              field: 'created_at',
              defaultSort: 'desc',
              render: (supplier: Supplier) =>
                supplier?.created_at ? (
                  <span>
                    {makeTimestampToMoment(supplier.created_at).format(
                      'DD.MM.YY'
                    )}
                    <br />
                    {makeTimestampToMoment(supplier.created_at).format('HH:mm')}
                  </span>
                ) : (
                  '-'
                ),
              editable: 'never'
            },
            {
              title: 'Min. Bestellwert.',
              field: 'min_order_value',
              type: 'numeric'
            },
            {
              title: 'Nachrichtenoptionen',
              field: 'preferred_types',
              render: (supplier: Supplier) => (
                <span>
                  {supplier.preferred_types.map((preferred_type, index) => (
                    <PreferredType type={preferred_type} key={index} />
                  ))}
                </span>
              ),
              editComponent: props => (
                <PreferredTypeEdit
                  data={props.value}
                  onChange={data => props.onChange(data)}
                />
              )
            },
            {
              title: 'Fax-Provider',
              field: 'faxProvider',
              render: (supplier: Supplier) => (
                <span>
                  {supplier.faxProvider === 'clicksend'
                    ? 'Clicksend'
                    : supplier.faxProvider === 'documo'
                    ? 'Documo'
                    : supplier.faxProvider === 'faxDe'
                    ? 'Fax.de'
                    : 'Default (Fax.de)'}
                </span>
              ),
              editComponent: ({ value, onChange }) => (
                <Select
                  value={value}
                  label="Provider"
                  onChange={({ target: { value: newValue } }) =>
                    onChange(newValue)
                  }>
                  <MenuItem value={'documo'}>Documo</MenuItem>
                  <MenuItem value={'clicksend'}>Clicksend</MenuItem>
                  <MenuItem value={'faxDe'}>Fax.de</MenuItem>
                </Select>
              )
            },
            {
              title: 'Icon',
              field: 'icon_path',
              render: (row: any) =>
                row.icon_path && <SupplierImage imagePath={row.icon_path} />,
              editComponent: (row: any) => {
                return (
                  <div className={classes.imageEditContainer}>
                    {row.rowData.icon_path && (
                      <Delete
                        onClick={() => row.onChange()}
                        className={classes.imageEditDeleteIcon}
                      />
                    )}
                    <input
                      type="file"
                      accept="image/*"
                      id="input-file"
                      style={{ display: 'none' }}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        row.onChange(event?.target?.files[0])
                      }}
                    />
                    <label htmlFor="input-file">
                      {row.rowData.icon_path ? (
                        <EditIcon className={classes.imageEditEditIcon} />
                      ) : (
                        <AddIcon className={classes.imageEditAddIcon} />
                      )}
                    </label>

                    {row.rowData.icon_path && (
                      <SupplierImage imagePath={row.rowData.icon_path} />
                    )}
                  </div>
                )
              }
            }
          ]}
          data={convertedData}
          localization={localization}
          title="Supplier"
          options={{
            pageSize: 20,
            pageSizeOptions: [10, 20, 50],
            addRowPosition: 'first',
            searchText
          }}
          onSearchChange={onSearchChange}
        />
      </div>
    </div>
  )
}
export default SupplierList
