/* eslint-disable react-hooks/exhaustive-deps */
import { Merchant } from '@hierfoods/interfaces'
import { createStyles, makeStyles, TextField, Theme } from '@material-ui/core'
import { selectMerchantList } from '@selectors/merchant'
import MaterialTable from 'material-table'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useFirebase, useFirebaseConnect } from 'react-redux-firebase'
import { makeTimestampToMoment, useSearchQuery } from '../../services/helpers'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    loader: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2)
      }
    },
    orderPrice: {
      fontWeight: 'bold'
    },
    icon: {
      color: 'black',
      fontSize: 16
    }
  })
)

const MerchantsList = () => {
  const { t } = useTranslation()
  const firebase = useFirebase()
  const classes = useStyles()
  const localization = {
    body: {
      emptyDataSourceMessage: t('messages.noData'),
      addTooltip: t('buttons.add'),
      deleteTooltip: t('buttons.delete'),
      editTooltip: t('buttons.edit'),
      editRow: {
        deleteText: t('messages.delete'),
        cancelTooltip: t('buttons.cancel'),
        saveTooltip: t('buttons.save')
      }
    },
    pagination: {
      labelDisplayedRows: t('pagination.label'),
      labelRowsSelect: t('pagination.rows'),
      labelRowsPerPage: t('pagination.perPage'),
      firstAriaLabel: t('pagination.first'),
      firstTooltip: t('pagination.first'),
      previousAriaLabel: t('pagination.previous'),
      previousTooltip: t('pagination.previous'),
      nextAriaLabel: t('pagination.next'),
      nextTooltip: t('pagination.next'),
      lastAriaLabel: t('pagination.last'),
      lastTooltip: t('pagination.last')
    },
    toolbar: {
      searchTooltip: t('buttons.search'),
      searchPlaceholder: t('buttons.search')
    }
  }

  useFirebaseConnect([`merchants`])

  const { onSearchChange, searchText } = useSearchQuery()

  const data: Merchant[] = useSelector(selectMerchantList)

  return (
    <div className={classes.root}>
      <MaterialTable
        columns={[
          {
            title: 'ID',
            field: 'id',
            editable: 'onAdd'
          },
          {
            title: 'Erstellt am',
            field: 'created_at',
            render: (merchant: Merchant) =>
              merchant?.created_at ? (
                <span>
                  {makeTimestampToMoment(merchant.created_at).format(
                    'DD.MM.YY'
                  )}
                  <br />
                  {makeTimestampToMoment(merchant.created_at).format('HH:mm')}
                </span>
              ) : (
                '-'
              ),
            editable: 'never'
          },
          {
            title: 'Name',
            field: 'name',
            defaultSort: 'asc'
          },
          {
            title: 'Adresse',
            field: 'address',
            editComponent: (row: any) => (
              <TextField
                label={t('address')}
                multiline
                rows={3}
                defaultValue={row.value}
                onChange={e => {
                  row.onChange(e.target.value)
                }}
                style={{ width: '100%' }}
              />
            )
          },
          {
            title: 'OrderUser ausblenden',
            field: 'settings.hideOrderUser',
            type: 'boolean'
          },
          {
            title: 'Produkt-ID immer anzeigen',
            field: 'settings.forceShowProductID',
            type: 'boolean'
          }
        ]}
        editable={{
          onRowAdd: (merchant: Merchant) =>
            new Promise(async resolve => {
              await (firebase as any)
                .database()
                .ref()
                .child(`merchants/${merchant.id}`)
                .set({ ...merchant, created_at: Date.now() })

              resolve(true)
            }),
          onRowUpdate: (merchant: Merchant) =>
            new Promise(async (resolve, reject) => {
              await firebase.update(`merchants/${merchant.id}`, {
                name: merchant.name || null,
                created_at: merchant.created_at || null,
                address: merchant.address || null,
                settings: merchant.settings || null
              })
              resolve(true)
            })
        }}
        data={data}
        localization={localization}
        title="Merchants"
        options={{
          pageSize: 20,
          pageSizeOptions: [10, 20, 50],
          addRowPosition: 'first',
          searchText
        }}
        onSearchChange={onSearchChange}
      />
    </div>
  )
}
export default MerchantsList
