import mapValues from 'lodash/mapValues'
import { createSelector } from 'reselect'
import { CategoryEntries } from 'src/components/lists/supplierCategoriesList'
import { selectSupplier } from './supplier'
import { createDeepEqualSelector } from './util'
import { mapToArrayWithID } from '../services/helpers'

export const selectSupplierCategories = createDeepEqualSelector(
  selectSupplier,
  (s: any) => s.firebase.data?.supplier_categories,
  (supplier, supplierCategories): { [key: string]: string } =>
    mapValues(supplierCategories?.[supplier?.id] || {}, 'title')
)

export const selectSupplierCategoryItems = createDeepEqualSelector(
  selectSupplier,
  (s: any) => s.firebase.data?.supplier_categories,
  (supplier, supplierCategories) => {
    return supplier && supplierCategories
      ? mapToArrayWithID(supplierCategories[supplier.id] || {})
      : []
  }
)

export const selectSupplierCategoriesForAutocomplete = createDeepEqualSelector(
  selectSupplierCategories,
  (supplierCategories) =>
    Object.entries(supplierCategories).map(([key, title]) => ({
      title,
      key
    }))
)

export const selectLastSupplierCategory = createDeepEqualSelector(
  (s: any) => s.firebase.data?.lastSupplierCategory,
  (lastSupplierCategory) =>
    lastSupplierCategory ? Object.values(lastSupplierCategory)[0] : {}
)

export const selectSupplierCategoriesOrdered = createSelector(
  (s: any) => s?.firebase?.data?.supplier_categories,
  (_s: any, supplierId: string) => supplierId,
  (supplierCategories, supplierId: string): CategoryEntries => {
    const categoryEntries: CategoryEntries =
      supplierCategories && supplierCategories[supplierId]
        ? Object.entries(supplierCategories[supplierId])
        : []
    return categoryEntries.sort((a, b) => a[1].order - b[1].order)
  }
)
