import { selectSupplier } from '@selectors/supplier'
import * as React from 'react'
import { useSelector } from 'react-redux'
import SupplierCategoriesList from 'src/components/lists/supplierCategoriesList'
import SupplierDropdown from 'src/components/supplierDropdown'

const SupplierCategories = () => {
  const supplier = useSelector(selectSupplier)

  return (
    <div>
      <div style={{ marginBottom: '10px' }}>
        <SupplierDropdown />
      </div>
      {supplier ? <SupplierCategoriesList /> : <p>Please select a supplier</p>}
    </div>
  )
}

export default SupplierCategories
