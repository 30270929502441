import mapValues from 'lodash/mapValues'
import { createDeepEqualSelector } from './util'

export const selectBulkUnits = createDeepEqualSelector(
  (s: any) => s.firebase.data?.bulk_units,
  (bulk_units) => (bulk_units ? mapValues(bulk_units, 'title') : {})
)
export const selectBulkUnitsForAutocomplete = createDeepEqualSelector(
  selectBulkUnits,
  (bulkUnitsObject) =>
    Object.keys(bulkUnitsObject).map((key) => ({
      title: bulkUnitsObject[key],
      key
    }))
)
export const selectSingleUnits = createDeepEqualSelector(
  (s: any) => s.firebase.data?.single_units,
  (single_units) => (single_units ? mapValues(single_units, 'title') : {})
)
export const selectSingleUnitsForAutocomplete = createDeepEqualSelector(
  selectSingleUnits,
  (singleUnitsObject) =>
    Object.keys(singleUnitsObject).map((key) => ({
      title: singleUnitsObject[key],
      key
    }))
)
export const selectProductTypes = createDeepEqualSelector(
  (s: any) => s.firebase.data?.product_types,
  (product_types) => (product_types ? mapValues(product_types, 'title') : {})
)
export const selectProductTypesForAutocomplete = createDeepEqualSelector(
  selectProductTypes,
  (productTypesObject) =>
    Object.keys(productTypesObject)
      .map((key) => ({
        title: productTypesObject[key],
        key
      }))
      .filter((el) => el.title && el.title.length > 0)
)
export const selectVAT = createDeepEqualSelector(
  (s: any) => s.firebase.data?.vat,
  (vat) => (vat ? mapValues(vat, 'value') : {})
)
export const selectVATForAutocomplete = createDeepEqualSelector(
  selectVAT,
  (vatObject) =>
    Object.keys(vatObject).map((key) => ({
      value: vatObject[key],
      //title: vatObject?.[key]?.toString(),
      key
    }))
)
