/* eslint-disable react-hooks/exhaustive-deps */
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { selectDevicesMapped } from '@selectors/devices'
import mapValues from 'lodash/mapValues'
import MaterialTable from 'material-table'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useFirebaseConnect } from 'react-redux-firebase'
import { makeTimestampToMoment, useSearchQuery } from '../../services/helpers'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    loader: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2)
      }
    },
    orderPrice: {
      fontWeight: 'bold'
    },
    icon: {
      color: 'black',
      fontSize: 16
    },
    selectorContainer: {
      flexDirection: 'row',
      flex: 1,
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      paddingBottom: 20
    }
  })
)

const FavoritesList = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const localization = {
    body: {
      emptyDataSourceMessage: t('messages.noData'),
      addTooltip: t('buttons.add'),
      deleteTooltip: t('buttons.delete'),
      editTooltip: t('buttons.edit'),
      editRow: {
        deleteText: t('messages.delete'),
        cancelTooltip: t('buttons.cancel'),
        saveTooltip: t('buttons.save')
      }
    },
    pagination: {
      labelDisplayedRows: t('pagination.label'),
      labelRowsSelect: t('pagination.rows'),
      labelRowsPerPage: t('pagination.perPage'),
      firstAriaLabel: t('pagination.first'),
      firstTooltip: t('pagination.first'),
      previousAriaLabel: t('pagination.previous'),
      previousTooltip: t('pagination.previous'),
      nextAriaLabel: t('pagination.next'),
      nextTooltip: t('pagination.next'),
      lastAriaLabel: t('pagination.last'),
      lastTooltip: t('pagination.last')
    },
    toolbar: {
      searchTooltip: t('buttons.search'),
      searchPlaceholder: t('buttons.search')
    },
    header: { actions: 'Favorit (toggle)' }
  }

  useFirebaseConnect([`devices`, `merchants`])

  const { onSearchChange, searchText } = useSearchQuery()

  const data: any = useSelector(selectDevicesMapped)
  const merchants = useSelector((s: any) => s?.firebase?.data?.merchants || {})
  const merchantsMapped = mapValues(merchants, 'name')

  return (
    <div className={classes.root}>
      <MaterialTable
        columns={[
          { title: 'Merchant', field: 'merchantID', lookup: merchantsMapped },
          {
            title: 'Brand',
            field: 'brand'
          },
          {
            title: 'Modell',
            field: 'model'
          },
          {
            title: 'OS',
            field: 'os'
          },
          {
            title: 'RevisionID',
            field: 'revisionId'
          },
          {
            title: 'AppVersion',
            field: 'nativeAppVersion'
          },
          {
            title: 'BuildVersion',
            field: 'nativeBuildVersion'
          },
          {
            title: 'Letzter Login',
            field: 'lastLogin',
            defaultSort: 'desc',
            render: (field: any) =>
              makeTimestampToMoment(field.lastLogin).isValid() ? (
                <span>
                  {makeTimestampToMoment(field.lastLogin).format('DD.MM.YYYY')}
                  <br />
                  {makeTimestampToMoment(field.lastLogin).format('HH:mm')}
                </span>
              ) : (
                <></>
              )
          }
        ]}
        data={data}
        localization={localization}
        title="Geräte"
        options={{
          pageSize: 20,
          pageSizeOptions: [10, 20, 50],
          addRowPosition: 'first',
          searchText
        }}
        onSearchChange={onSearchChange}
      />
    </div>
  )
}
export default FavoritesList
