import { Order, OrderStatus } from './order'

export interface OrderProduct {
  amount: number
  order_id: string
  product_id: string
  status?: OrderStatus
  id?: number | string
  originalData?: {
    bulkPrice?: number
    defaultPrice?: number | string
    singleUnit?: string
    bulkUnit?: string
    productType?: string
    name?: string
    vat?: number
  }
  /**
   * @deprecated This Type was succeeded by originalData
   */
  bulk_price?: number
  /**
   * Supplier_id is only in here if the order_product was confirmed by the supplier
   */
  supplier_id?: string
}
export interface OrderProductDetails {
  bulk_amount?: number
  bulk_price?: number
  default_price?: number
  /**
   * This is the fee we charge the merchant per product for our pilot
   */
  fee?: number
  /**
   * @deprecated Do not use
   */
  single_price?: number
  /**
   * @deprecated Do not use, succeeded by bulk_unit_id
   */
  bulk_unit?: string
  /**
   * @deprecated Do not use, succeeded by single_unit_id
   */
  single_unit?: string
  /**
   * @deprecated Do not use, succeeded by product_type_id
   */
  product_type?: string

  // ID's
  category_id?: string
  bulk_unit_id?: string
  single_unit_id?: string
  vat_id?: string
  supplier_category_id?: string
  product_type_id?: string

  soft_deleted?: boolean
  wasCustomAdded?: boolean

  /**
   * @deprecated Do not use, this is just a type for admin
   */
  image?: any
  image_path?: string
  image_path_full?: string

  supplier_id?: string
  title: string
  id: string
  supplier_product_id?: string
  ean?: string
  delta?: 'new' | 'back' | number | null
  weight?: number
  volume?: number

  /**
   * @deprecated This Type was used for one rewe but has since been deprecated IIRC
   */
  selling_price?: number

  created_at?: number
  updated_at?: number
}
export type Product = OrderProductDetails
export interface RevisionedOrderProductDetails extends OrderProductDetails {
  revisioned_at: number
}
export interface OrderProducts {
  accepted?: boolean
  initialAmount?: number
  initialPrice?: number
  product: OrderProduct
  productDetails: OrderProductDetails
}

export interface OrderWithProducts extends Order {
  order_products?: OrderProducts[]
  removed?: string[]
  category_filtered?: boolean
}
export interface MerchantApiOrderProducts extends OrderProduct {
  orderedPriceForApi: number
  currentPriceForApi?: number
  productDetailsForApi?: OrderProductDetails
  created_at?: number
  created_at_formatted?: string
}
export type OrderProductsWithDetailsForSendOrder = {
  product: OrderProduct & { priceForAll: number; bulk_price: number }
  productDetails: OrderProductDetails
}[]
export interface RevisionedOrder extends Order {
  order_products: {
    product: OrderProduct
    productDetails: OrderProductDetails
  }[]
}
export enum ProductColums {
  SoftDeleted = 'Versteckt',
  UpdatedAt = 'Letztes Update',
  ID = 'HIER Artikelnummer',
  SupplierProductID = 'Artikelnummer',
  EAN = 'EAN/GTIN',
  ProductTypeID = 'Typ',
  Title = 'Titel',
  BulkUnitID = 'Liefereinheit',
  BulkAmount = 'Anzahl',
  SingleUnitID = 'Einzeleinheit',
  Weight = 'Gewicht in g',
  Volume = 'Volumen in ml',
  BulkPrice = 'EK',
  Fee = 'Pilot Gebühren',
  SellingPrice = 'VK',
  VAT_ID = 'VAT',
  DefaultPrice = 'Standardpreise',
  CategoryID = 'Kategorie',
  SupplierCategoryID = 'Supplier Kategorie',
  ImagePath = 'Bild'
}

export interface CreateCustomProduct {
  supplierID: string
  amount: number
  description: string
}
