/* eslint-disable react-hooks/exhaustive-deps */
import { MerchantSupplier } from '@hierfoods/interfaces'
import { createStyles, makeStyles, TextField, Theme } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import Input from '@material-ui/core/Input'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { selectMerchant } from '@selectors/merchant'
import { selectSupplierListWithMerchantConnection } from '@selectors/supplier'
import MaterialTable from 'material-table'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useFirebase, useFirebaseConnect } from 'react-redux-firebase'
import MerchantDropdown from '../merchantDropdown'
import moment from 'moment'
import { useIsMount, useSearchQuery, useTableRef } from '../../services/helpers'
export interface MerchantSupplierWithChecked extends MerchantSupplier {
  isChecked: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      overflowX: 'hidden'
    },
    loader: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2)
      }
    },
    orderPrice: {
      fontWeight: 'bold'
    },
    icon: {
      color: 'black',
      fontSize: 16
    },
    selectorContainer: {
      paddingBottom: 20
    },
    checkboxContainer: {
      display: 'flex',
      flex: 1
    },
    checkboxItem: {
      width: 150,
      flexDirection: 'row',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    inputContainer: {
      display: 'flex',
      flex: 1,
      justifyContent: 'space-between',
      alignItems: 'center',
      '& input': {
        height: 40
      }
    }
  })
)
interface DeliveryDay {
  day: number
  orderBy?: number
}
const MenuItemWithSlider = ({ day, row }: { day: number; row: any }) => {
  /* OrderBy = number of hours you have to order before end of day
  Can range from 0 (23:59:59 of the same day) 
  to 168 (7 days before)
  */
  const { t } = useTranslation()
  const classes = useStyles()
  const isMount = useIsMount()

  const rowValue = row.value?.find((v: DeliveryDay) => v.day === day)
  const initialValue = rowValue?.orderBy !== undefined ? rowValue.orderBy : 24
  // Default value is 12 -> Noon
  const [slider, setSlider] = useState(initialValue)
  const [showOrderBy, setShowOrderBy] = useState(
    rowValue?.orderBy !== undefined && rowValue?.orderBy !== null
  )
  // This Offset Slider is for the UI (in the UI 12 / noon is a 0 hours Offset), basically the UI zeroes out at noon
  const sliderOffsetForDisplay = slider - 12

  useEffect(() => {
    // Legacy, convert it
    if (typeof row.value?.[0] === 'number' && isMount) {
      row.onChange(row.value.map((day: number) => ({ day, orderBy: 12 })))
    }
  }, [row.value])

  const onChange = (remove: boolean = false, sliderValue?: number) => {
    const newOrderBy = sliderValue ?? slider ?? initialValue
    const copy = row.value ? [...row.value] : []
    if (copy.some((d: DeliveryDay) => d.day === day)) {
      // Already existing
      const index = copy.findIndex((d: DeliveryDay) => d.day === day)
      if (remove) {
        copy.splice(index, 1)
        setShowOrderBy(false)
      } else copy[index] = { day, orderBy: showOrderBy ? newOrderBy : null }
    } else if (!remove) {
      // New, add it
      copy.push({ day, orderBy: showOrderBy ? newOrderBy : null })
    }
    row.onChange(copy?.length > 0 ? copy : null)
  }

  useEffect(() => {
    if (!isMount) {
      if (showOrderBy) onChange(false)
      else onChange(!!!rowValue)
    }
  }, [showOrderBy])

  return (
    <MenuItem
      key={day}
      value={day}
      style={{
        width: 800,
        height: 120,
        borderBottom: '1px solid gray'
      }}>
      <div className={classes.checkboxContainer}>
        <div className={classes.checkboxItem}>
          <Checkbox
            checked={!!rowValue}
            onChange={event => onChange(!event.target.checked)}
          />
          <ListItemText primary={t(`days.${day}`)} />
        </div>
        <div className={classes.checkboxItem}>
          <Checkbox
            checked={showOrderBy}
            onChange={event => {
              setShowOrderBy(v => !v)
            }}
          />
          <ListItemText primary={t('merchantSupplier.showSlider')} />
        </div>
      </div>
      {showOrderBy && (
        <div
          onClick={e => e.stopPropagation()}
          className={classes.inputContainer}>
          <input
            type="number"
            value={slider - 12}
            step={0.25}
            min={-12}
            max={168}
            onChange={e => {
              const val = Math.min(
                Math.max(
                  Math.ceil((parseFloat(e.target.value) + 12) / 0.25) * 0.25,
                  0
                ),
                180
              )
              const safeNumber = Number.isNaN(val) ? 12 : val
              setSlider(safeNumber)
              onChange(false, safeNumber)
            }}
          />

          <p>
            {`Bestellen bis: ${moment()
              .weekday(day)
              .endOf('day')
              .subtract(slider, 'hours')
              .format('dddd, HH:mm')} Uhr`}
            <br />
            {`(${sliderOffsetForDisplay} Stunden ${
              sliderOffsetForDisplay >= 0 ? 'vorher' : 'nacher'
            })`}
          </p>
        </div>
      )}
    </MenuItem>
  )
}

const MerchantSupplierList = () => {
  const { t } = useTranslation()
  const firebase = useFirebase()
  const classes = useStyles()
  const localization = {
    body: {
      emptyDataSourceMessage: t('messages.noData'),
      addTooltip: t('buttons.add'),
      deleteTooltip: t('buttons.delete'),
      editTooltip: t('buttons.edit'),
      editRow: {
        deleteText: t('messages.delete'),
        cancelTooltip: t('buttons.cancel'),
        saveTooltip: t('buttons.save')
      }
    },
    pagination: {
      labelDisplayedRows: t('pagination.label'),
      labelRowsSelect: t('pagination.rows'),
      labelRowsPerPage: t('pagination.perPage'),
      firstAriaLabel: t('pagination.first'),
      firstTooltip: t('pagination.first'),
      previousAriaLabel: t('pagination.previous'),
      previousTooltip: t('pagination.previous'),
      nextAriaLabel: t('pagination.next'),
      nextTooltip: t('pagination.next'),
      lastAriaLabel: t('pagination.last'),
      lastTooltip: t('pagination.last')
    },
    toolbar: {
      searchTooltip: t('buttons.search'),
      searchPlaceholder: t('buttons.search')
    },
    header: { actions: 'Verknüpft (toggle)' }
  }
  const merchant = useSelector(selectMerchant)

  useFirebaseConnect([`suppliers`, `merchant_suppliers/${merchant?.id}`])
  const tableRef = useTableRef([merchant?.id])

  const { onSearchChange, searchText } = useSearchQuery()

  const data: MerchantSupplierWithChecked[] = useSelector(
    selectSupplierListWithMerchantConnection
  )
  /* 
  workaround for our switching period from preferred_type to preferred_typeS!
   */
  const toggleConnected = async (supplierID: string, value: boolean) => {
    if (!value && !window.confirm(t('merchantSupplier.removeConnection')))
      return

    const ref = (firebase as any)
      .database()
      .ref(`merchant_suppliers/${merchant.id}/${supplierID}`)
    ref.set(value ? { order_number: 0 } : null)
  }
  const openChat = async (supplierID: string) => {
    const merSupSnapshot = await (firebase as any)
      .database()
      .ref(`merchant_supplier_shortcodes`)
      .orderByChild('merchant_id')
      .equalTo(`${merchant.id}`)
      .once('value')
    const merSupChatcodes = merSupSnapshot.val()
    let shortCode = Object.keys(merSupChatcodes).find(scKey => {
      const val = merSupChatcodes[scKey]
      if (
        `${val.supplier_id}` === `${supplierID}` &&
        `${val.merchant_id}` === `${merchant.id}`
      )
        return scKey
      return false
    })
    if (shortCode)
      window.open(
        `${process.env.REACT_APP_FIREBASE_HOSTING}/m/${shortCode}`,
        '_blank'
      )
  }

  const onRowUpdate = (merchantSupplier: any) => {
    return new Promise(async resolve => {
      const {
        isChecked,
        customerID,
        id,
        order_number,
        delivery_days,
        custom_message,
        default_delivery_today,
        append_to_favorites_on_order,
        favorites_expiration_in_days,
        is_pilot
      } = merchantSupplier
      let data
      if (!isChecked) {
        data = null
      } else {
        data = {
          customerID: customerID?.length > 0 ? customerID : null,
          order_number: order_number || 0,
          delivery_days: delivery_days || null,
          custom_message: custom_message || null,
          default_delivery_today: default_delivery_today || null,
          append_to_favorites_on_order: append_to_favorites_on_order || null,
          favorites_expiration_in_days: favorites_expiration_in_days || null,
          is_pilot: is_pilot || null
        }
      }
      await firebase.ref(`merchant_suppliers/${merchant.id}/${id}`).set(data)
      resolve(true)
    })
  }
  return (
    <div className={classes.root}>
      <div className={classes.selectorContainer}>
        <MerchantDropdown />
      </div>
      {merchant ? (
        <div style={{ maxWidth: '90vw' }}>
          <MaterialTable
            actions={[
              rowData => ({
                icon: rowData.isChecked
                  ? 'check_box'
                  : 'check_box_outline_blank',
                tooltip: 'Verknüpft (toggle)',
                onClick: () => toggleConnected(rowData.id, !rowData.isChecked)
              }),
              rowData => ({
                icon: 'chat',
                tooltip: 'Chat öffnen',
                onClick: () => openChat(rowData.id)
              })
            ]}
            columns={[
              {
                title: 'Verknüpft (filter)',
                field: 'isChecked',
                defaultSort: 'desc',
                type: 'boolean',
                hiddenByColumnsButton: true,
                hidden: true
              },
              {
                title: 'Standard-Liefertag Heute',
                field: 'default_delivery_today',
                type: 'boolean',
                hiddenByColumnsButton: true,
                hidden: true
              },
              {
                title: 'Liefertage',
                field: 'delivery_days',
                render: rowData => {
                  const mappedDeliveryDays: {
                    day: string
                    reminder?: string
                  }[] = (rowData.delivery_days as any)?.map(
                    (d: DeliveryDay | number) => {
                      if (typeof d === 'number')
                        return {
                          day: moment().weekday(d).endOf('day').format('dd')
                        }
                      const reminder = d.orderBy
                        ? moment()
                            .weekday(d.day)
                            .endOf('day')
                            .subtract(d.orderBy, 'hours')
                            .format(' (dd HH:mm)')
                        : undefined

                      return {
                        day: moment().weekday(d.day).endOf('day').format('dd'),
                        reminder
                      }
                    }
                  )
                  if (mappedDeliveryDays?.length > 0)
                    return (
                      <>
                        {mappedDeliveryDays.map(d => (
                          <span>
                            <b>
                              <span>{d.day}</span>
                            </b>
                            {d.reminder && (
                              <span style={{ color: 'gray' }}>
                                {d.reminder}
                              </span>
                            )}
                            <br />
                          </span>
                        ))}
                      </>
                    )
                  return null
                },
                editComponent: (row: any) => (
                  <Select
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    multiple
                    value={row.value || []}
                    input={<Input />}
                    renderValue={(selected: any) =>
                      selected
                        .map((d: DeliveryDay | number) =>
                          t(`days.${typeof d === 'object' ? d.day : d}`)
                        )
                        .join(', ')
                    }>
                    {[0, 1, 2, 3, 4, 5, 6].map(day => (
                      <MenuItemWithSlider day={day} row={row} key={day} />
                    ))}
                  </Select>
                )
              },
              {
                title: 'CustomerID',
                field: 'customerID'
              },

              {
                title: 'OrderNumber',
                field: 'order_number',
                type: 'numeric'
              },
              {
                title: 'ID',
                field: 'id',
                editable: 'never'
              },
              {
                title: 'Beschreibung (in der App sichtbar)',
                field: 'custom_message',
                headerStyle: { minWidth: 300 },
                cellStyle: { minWidth: 300 },
                export: true,
                editComponent: (row: any) => (
                  <TextField
                    label={'Beschreibung (in der App sichtbar)'}
                    multiline
                    rows={2}
                    defaultValue={row.value}
                    onChange={e => {
                      row.onChange(e.target.value)
                    }}
                    style={{ width: '100%' }}
                  />
                )
              },
              {
                title: 'Name.',
                field: 'name',
                // @ts-ignore
                width: 30,
                editable: 'never'
              },
              {
                title: 'AutoFavorite',
                field: 'append_to_favorites_on_order',
                defaultSort: 'desc',
                type: 'boolean'
              },
              {
                title: 'Auto-Delete Favorites After (days)',
                tooltip:
                  'If product wasn’t purchased in this amount of days, it will be auto-removed from favorites when new orders are made',
                field: 'favorites_expiration_in_days',
                type: 'numeric',
                // adding `editComponent` setting here to allow setting `min`
                // on input
                editComponent: (props: any) => {
                  return (
                    <TextField
                      type="number"
                      defaultValue={props.value}
                      inputProps={{ min: 1 }}
                      onChange={e => {
                        const value = parseInt(e.target.value, 10)
                        props.onChange(value < 1 ? 1 : value)
                      }}
                    />
                  )
                }
              },
              {
                title: 'Pilot',
                field: 'is_pilot',
                type: 'boolean',
                hiddenByColumnsButton: true,
                hidden: true
              }
            ]}
            data={data}
            localization={localization}
            title="MerchantSuppliers"
            editable={{
              onBulkUpdate: async changes => {
                for await (const change of Object.values(changes)) {
                  onRowUpdate(change.newData)
                }
              },
              onRowUpdate: onRowUpdate
            }}
            options={{
              pageSize: 20,
              pageSizeOptions: [10, 20, 50],
              addRowPosition: 'first',
              columnsButton: true,
              searchText
            }}
            tableRef={tableRef}
            onSearchChange={onSearchChange}
          />
        </div>
      ) : (
        <span>Bitte Merchant auswählen</span>
      )}
    </div>
  )
}
export default MerchantSupplierList
