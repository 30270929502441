/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { useFirebase } from 'react-redux-firebase'
import { fileUrl } from '@services/storage'

const ProductImage = (props: any) => {
  const firebase = useFirebase()

  const { imagePath, origin } = props
  const [url, setUrl] = useState()

  useEffect(() => {
    if (origin) {
      setUrl(imagePath)
    } else {
      fileUrl(imagePath, firebase)
        .then((url) => {
          setUrl(url)
        })
        .catch(() => {
          setUrl(imagePath)
        })
    }
  }, [firebase, imagePath, origin])

  return <img src={url} width={120} style={{ display: 'flex' }}></img>
}

export default ProductImage
