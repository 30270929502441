import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import BackupIcon from '@material-ui/icons/Backup'
import { FileMapping } from './types'
import { defaultTemplates } from './configuration'

const useUploadStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'center'
    },
    optionContainer: {
      padding: '5px 3px'
    },
    uploadArea: {
      background: 'rgb(245, 248, 250)',
      border: '1px dashed rgb(81, 111, 144)',
      color: 'rgb(124, 152, 182)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 10
    },
    info: {
      padding: '10px 0'
    },
    fileUpload: {},
    fileUploadInput: {}
  })
)

const readCSV = async (setImporterFile: (s: File) => void, e: any) => {
  e.preventDefault()
  setImporterFile(e.target.files[0] as File)
}

export function UploadStep(props: {
  csv: File
  setCSVFile: (csv: File) => void
  mapping: FileMapping
  setMapping: (mapping: FileMapping) => void
}) {
  const { mapping, setMapping } = props
  const classes = useUploadStyles()

  return (
    <div className={classes.root}>
      <div className={classes.optionContainer}>
        <div>Template</div>
        <select
          value={mapping.preProcessingStratergy}
          onChange={(e) => {
            const value = e.target.value

            const stratergy = defaultTemplates.find((el) => el.name === value)

            setMapping({
              ...mapping,
              preProcessingStratergy: stratergy ? value : undefined,
              encoding: stratergy.encoding,
              fields: stratergy ? stratergy.fieldsMapping : mapping.fields || {}
            })
          }}>
          <option value={''}>choose a template</option>
          {defaultTemplates.map((stratergy, i) => {
            return (
              <option key={i} value={stratergy.name}>
                {stratergy.name}
              </option>
            )
          })}
        </select>
      </div>
      <div className={classes.optionContainer}>
        Show only matched products{' '}
        <input
          checked={mapping.deleteUnmatched}
          onChange={(e) => {
            setMapping({
              ...mapping,
              deleteUnmatched: !mapping.deleteUnmatched
            })
          }}
          type="checkbox"
        />
      </div>
      <h3>Prepare & upload your file</h3>
      <label htmlFor="file-upload" className={classes.fileUpload}>
        <div className={classes.uploadArea}>
          <BackupIcon style={{ fontSize: 40 }} />
          Please select a CSV file
          <input
            autoFocus
            id="file-upload"
            type="file"
            accept="text/csv"
            className={classes.fileUploadInput}
            onChange={readCSV.bind(null, props.setCSVFile)}
          />
        </div>
      </label>
    </div>
  )
}
