/* eslint-disable react-hooks/exhaustive-deps */
import { Product } from '@hierfoods/interfaces'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { selectMerchant } from '@selectors/merchant'
import { selectProductsWithFavoritesForSupplier } from '@selectors/product'
import { selectSupplier } from '@selectors/supplier'
import { selectProductTypes } from '@selectors/units'
import MaterialTable from 'material-table'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useFirebase, useFirebaseConnect } from 'react-redux-firebase'
import { makeTimestampToMoment, useSearchQuery } from '../../services/helpers'
import MerchantDropdown from '../merchantDropdown'
import SupplierDropdown from '../supplierDropdown'

export interface ProductWithFavorite extends Product {
  isFavorite: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    loader: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2)
      }
    },
    orderPrice: {
      fontWeight: 'bold'
    },
    icon: {
      color: 'black',
      fontSize: 16
    },
    selectorContainer: {
      flexDirection: 'row',
      flex: 1,
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      paddingBottom: 20
    }
  })
)

const FavoritesList = () => {
  const { t } = useTranslation()
  const firebase = useFirebase()
  const classes = useStyles()
  const localization = {
    body: {
      emptyDataSourceMessage: t('messages.noData'),
      addTooltip: t('buttons.add'),
      deleteTooltip: t('buttons.delete'),
      editTooltip: t('buttons.edit'),
      editRow: {
        deleteText: t('messages.delete'),
        cancelTooltip: t('buttons.cancel'),
        saveTooltip: t('buttons.save')
      }
    },
    pagination: {
      labelDisplayedRows: t('pagination.label'),
      labelRowsSelect: t('pagination.rows'),
      labelRowsPerPage: t('pagination.perPage'),
      firstAriaLabel: t('pagination.first'),
      firstTooltip: t('pagination.first'),
      previousAriaLabel: t('pagination.previous'),
      previousTooltip: t('pagination.previous'),
      nextAriaLabel: t('pagination.next'),
      nextTooltip: t('pagination.next'),
      lastAriaLabel: t('pagination.last'),
      lastTooltip: t('pagination.last')
    },
    toolbar: {
      searchTooltip: t('buttons.search'),
      searchPlaceholder: t('buttons.search')
    },
    header: { actions: 'Favorit (toggle)' }
  }
  const supplier = useSelector(selectSupplier)
  const merchant = useSelector(selectMerchant)
  const productTypesMapped = useSelector(selectProductTypes)

  useFirebaseConnect([
    `favorites/${merchant?.id}/${supplier?.id}`,
    `products/${supplier?.id}`,
    'product_types'
  ])
  const { onSearchChange, searchText } = useSearchQuery()

  const data: ProductWithFavorite[] = useSelector(
    selectProductsWithFavoritesForSupplier
  )
  /*
  workaround for our switching period from preferred_type to preferred_typeS!
   */
  const toggleFavorite = async (productID: string) => {
    const ref = (firebase as any)
      .database()
      .ref(`favorites/${merchant.id}/${supplier.id}/`)
    const readData = await ref.once('value')
    const currentFavorites = readData.val() || []
    const existingIndex = currentFavorites.indexOf(productID)
    if (existingIndex > -1) {
      currentFavorites.splice(existingIndex, 1)
    } else currentFavorites.push(productID)
    ref.set(currentFavorites)
  }

  return (
    <div className={classes.root}>
      <div className={classes.selectorContainer}>
        <MerchantDropdown />
        <SupplierDropdown />
      </div>
      {supplier && merchant ? (
        <MaterialTable
          actions={[
            (rowData: ProductWithFavorite) => ({
              icon: rowData.isFavorite
                ? 'check_box'
                : 'check_box_outline_blank',
              tooltip: 'Favorit',
              onClick: () => toggleFavorite(rowData.id)
            })
          ]}
          columns={[
            {
              title: 'Favorit (sorting)',
              field: 'isFavorite',
              defaultSort: 'desc',
              type: 'boolean'
            },
            {
              title: 'Hidden',
              field: 'soft_deleted',
              type: 'boolean'
            },
            {
              title: 'Letztes Update',
              field: 'updated_at',
              render: (product: Product) =>
                product?.updated_at && (
                  <span>
                    {makeTimestampToMoment(product.updated_at).format(
                      'DD.MM.YY'
                    )}
                    <br />
                    {makeTimestampToMoment(product.updated_at).format('hh:mm')}
                  </span>
                )
            },
            {
              title: 'ID',
              field: 'id'
            },
            {
              title: 'Lieferanten ID',
              field: 'supplier_product_id'
            },
            {
              title: t('type'),
              field: 'product_type_id',
              lookup: productTypesMapped
            },
            {
              title: t('title'),
              field: 'title',
              cellStyle: {
                width: '20%'
              }
            }
          ]}
          data={data}
          localization={localization}
          title="Favoriten"
          options={{
            pageSize: 20,
            pageSizeOptions: [10, 20, 50],
            addRowPosition: 'first',
            searchText
          }}
          onSearchChange={onSearchChange}
        />
      ) : (
        <span>Bitte Supplier und Merchant auswählen</span>
      )}
    </div>
  )
}
export default FavoritesList
