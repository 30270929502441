import { SupplierState } from '@redux/supplier'
import { createSelector } from 'reselect'
import { selectMerchant } from './merchant'
import { MerchantRelationShipForSupplier } from '@hierfoods/interfaces'

const supplierStateSelector = (state: { supplier: SupplierState }) =>
  state.supplier

export const selectSupplier = createSelector(
  supplierStateSelector,
  supplierState => supplierState.supplier
)

export const selectSupplierList = createSelector(
  (s: any) => s?.firebase?.data?.suppliers,
  suppliers =>
    suppliers
      ? Object.keys(suppliers)
          .map(key => {
            let supplier = suppliers[key]
            supplier.id = key
            return supplier
          })
          .sort((sup1, sup2) => (sup1.name > sup2.name ? 1 : -1))
      : []
)

export const selectMerchantSuppliers = (s: any) => {
  return s.firebase?.data?.merchant_suppliers
}

export const selectMerchantRelationshipsForSupplier = createSelector(
  selectSupplier,
  selectMerchantSuppliers,
  (supplier, merchantsSuppliers): MerchantRelationShipForSupplier[] => {
    if (!supplier || !merchantsSuppliers) return []
    const list = Object.keys(merchantsSuppliers).reduce<
      MerchantRelationShipForSupplier[]
    >((state, merchantId) => {
      const merchantSupplierList = merchantsSuppliers[merchantId]
      const merchantSupplierListItem = merchantSupplierList[supplier.id]
      if (merchantSupplierListItem) {
        return [
          ...state,
          {
            merchantId,
            supplierId: supplier.id,
            customerID: merchantSupplierListItem.customerID,
            order_number: merchantSupplierListItem.order_number
          }
        ]
      }
      return state
    }, [])
    return list
  }
)

export const selectSupplierListWithMerchantConnection = createSelector(
  selectSupplierList,
  selectMerchant,
  selectMerchantSuppliers,
  (suppliers, merchant, merSups) => {
    const supsForMerchant = merSups?.[merchant?.id] || []
    const filteredMerSups =
      Object.keys(supsForMerchant).filter(key => supsForMerchant[key]) || []

    const matchedUpSuppliers = suppliers.map(supplier => {
      return {
        ...supplier,
        ...supsForMerchant?.[supplier.id],
        isChecked: filteredMerSups.includes(supplier.id)
      }
    })
    return matchedUpSuppliers
  }
)
