/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { useFirebase } from 'react-redux-firebase'
import { fileUrl } from '@services/storage'

const SupplierImage = (props: any) => {
  const firebase = useFirebase()

  const { imagePath, origin } = props
  const [url, setUrl] = useState()
  useEffect(() => {
    if (origin) {
      setUrl(imagePath)
    } else {
      fileUrl(imagePath, firebase)
        .then((url) => {
          setUrl(url)
        })
        .catch(() => {
          setUrl(imagePath)
        })
    }
  }, [firebase, imagePath, origin])
  if (imagePath?.name) {
    return (
      <img
        src={URL.createObjectURL(imagePath)}
        width={50}
        style={{ display: 'flex' }}></img>
    )
  }
  return <img src={url} width={50} style={{ display: 'flex' }}></img>
}

export default SupplierImage
