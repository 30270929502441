import { createSelector } from 'reselect'
import { selectSupplier } from './supplier'
import { selectMerchant } from './merchant'

const selectProducts = (s: {
  firebase: {
    data: { products: { [id: string]: { [key: string]: any } } }
  }
}) => s.firebase.data.products

export const selectProductsForSupplier = createSelector(
  selectSupplier,
  selectProducts,
  (supplier, products) => {
    const data = products?.[supplier?.id]
    // Still Loading
    if (data === undefined) return null
    // no Products yet
    if (data === null) return []
    return Object.entries(data).map(([key, value]) => {
      return { ...value, id: key }
    })
  }
)
const selectFavorites = (s: { firebase: { data: any } }) =>
  s.firebase.data.favorites

export const selectProductsWithFavoritesForSupplier = createSelector(
  selectSupplier,
  selectMerchant,
  selectFavorites,
  selectProductsForSupplier,
  (supplier, merchant, favorites, products) => {
    const selectedFavorites = favorites?.[merchant?.id]?.[supplier?.id] || []
    const favoriteProducts = products?.map((product) => ({
      ...product,
      isFavorite: selectedFavorites.includes(product.id)
    }))
    return favoriteProducts
  }
)
const selectPrices = (s: { firebase: { data: any } }) => s.firebase.data.prices

export const selectProductsWithPricesForSupplierMerchant = createSelector(
  selectSupplier,
  selectMerchant,
  selectPrices,
  selectProductsForSupplier,
  (supplier, merchant, prices, products) => {
    const selectedPrices =
      prices?.[supplier?.id]?.merchants?.[merchant?.id] || []
    const pricedProducts = products?.map((product) => ({
      ...product,
      merchant_bulk_price: selectedPrices?.[product.id]?.bulk_price,
      merchant_selling_price: selectedPrices?.[product.id]?.selling_price
    }))
    return pricedProducts
  }
)
