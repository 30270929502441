import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { selectMerchantList } from '@selectors/merchant'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useFirebaseConnect } from 'react-redux-firebase'
const MenuProps = {
  PaperProps: {
    style: {
      width: '80%',
      maxWidth: 1000,
      height: '80%',
      margin: 'auto',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    }
  },
  anchorEl: document.body
}
const MerchantListDropdown = ({ onChange, selectedMerchants }) => {
  const { t } = useTranslation()
  useFirebaseConnect(['merchants'])

  const merchants = useSelector(selectMerchantList)

  const mappedMerchants = useMemo(
    () =>
      merchants.map(mer => {
        const value = `${mer.name} (${mer.id})`
        return (
          <MenuItem key={mer.id} value={mer.id}>
            {value}
          </MenuItem>
        )
      }),
    [merchants]
  )

  const changeHandler = change => {
    const { value } = change.target
    if (value.includes('selectAllMerchants'))
      onChange({ target: { value: merchants.map(m => m.id) } })
    else onChange(change)
  }

  return (
    <FormControl style={{ width: '100%' }}>
      <InputLabel>{t('merchant')}</InputLabel>
      <Select
        multiple
        value={selectedMerchants}
        onChange={changeHandler}
        input={<Input />}
        MenuProps={MenuProps}>
        <MenuItem key={'selectAllMerchants'} value={'selectAllMerchants'}>
          <b>Select all Merchants</b>
        </MenuItem>
        {mappedMerchants}
      </Select>
    </FormControl>
  )
}

export default MerchantListDropdown
