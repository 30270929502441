import { action, on, payload, reducer } from 'ts-action'
import { Supplier } from '@hierfoods/interfaces'
/* Interfaces */

export interface SupplierState {
  supplier?: Supplier | null
  suppliers?: Array<Supplier>
}

/* Redux */
export const setSupplier = action('setSupplier', payload<Supplier | null>())
export const setSuppliers = action('setSuppliers', payload<Array<Supplier>>())

const initialState: SupplierState = {}

export const supplierReducer = reducer(
  initialState,
  on(setSupplier, (state, { payload }) => ({
    ...state,
    supplier: payload
  })),
  on(setSuppliers, (state, { payload }) => ({
    ...state,
    suppliers: payload
  }))
)
