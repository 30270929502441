import React from 'react'
import { useFirebase, useFirebaseConnect } from 'react-redux-firebase'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult
} from 'react-beautiful-dnd'
import { createStyles, makeStyles, Theme, WithStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { selectSupplier } from '@selectors/supplier'
import { selectSupplierCategoriesOrdered } from '@selectors/supplierCategories'
import { SupplierCategory } from '@hierfoods/interfaces'

/*
/supplier_categories
{
"-MUrQ3NaEXWXBt3aZ3Qa": {
  "-M_u2I0nJ9SNk7YTAyaN": {
    "title": "BIO-Obst",
    "order": 0
  },
  "-M_u2K5NYlbU9FZ_wyJ4": {
    "title": "BIO-Gemüse",
    "order": 1
  }
}
}
*/

export type CategoryEntries = [string, SupplierCategory][]

const styles = createStyles({
  root: {
    margin: '10px 0',
    minHeight: '500px'
  },
  category: {
    backgroundColor: '#fff',
    padding: '10px',
    border: '1px solid #ccc',
    marginBottom: '5px',
    userSelect: 'none'
  },
  button: {
    margin: 0
  },
  modalBg: {
    background: '#00000044',
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  input: {
    border: 'none',
    position: 'absolute',
    left: '50%',
    top: '40%',
    transform: 'translate(-50%, -50%)',
    fontSize: '30px',
    padding: '5px'
  },
  categoryEdit: {
    float: 'right',
    marginLeft: '10px',
    cursor: 'pointer',
    color: '#aaa',
    '&:hover': {
      color: '#000'
    }
  },
  categoryDelete: {
    '&:hover': {
      color: 'red'
    }
  }
})

const useStyles = makeStyles((theme: Theme) => styles)

const SupplierCategoriesList = () => {
  const classes = useStyles()
  const firebase = useFirebase()

  const supplier = useSelector(selectSupplier)

  useFirebaseConnect(`/supplier_categories/${supplier.id}`)

  let supplierCategoriesList: CategoryEntries = useSelector(state =>
    selectSupplierCategoriesOrdered(state, supplier.id)
  )

  const [isEditing, setIsEditing] = React.useState(false)
  const [selectedCategory, setSelectedCategory] = React.useState('')
  const [selectedCategoryTitle, setSelectedCategoryTitle] = React.useState('')

  const onInputEnter = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      if (selectedCategory) {
        const indexToUpdate = supplierCategoriesList.findIndex(
          ([key]) => key === selectedCategory
        )
        supplierCategoriesList[indexToUpdate][1].title = selectedCategoryTitle
        updateExistingCategory(supplierCategoriesList)
      } else {
        addNewCategory()
      }
      setIsEditing(false)
    }
  }

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setSelectedCategoryTitle(value)
  }

  const onModalDismiss = (event: React.MouseEvent) => {
    setIsEditing(false)
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }

    const categoriesCopy = Array.from(supplierCategoriesList)
    const [reorderedItem] = categoriesCopy.splice(result.source.index, 1)
    categoriesCopy.splice(result.destination.index, 0, reorderedItem)
    categoriesCopy.forEach((category, index) => {
      category[1].order = index
    })

    updateExistingCategory(categoriesCopy)
  }

  React.useEffect(() => {
    function onKeyDown(event: KeyboardEvent) {
      const isEscape = event.key === 'Escape' || event.key === 'Esc'
      if (isEscape) {
        setIsEditing(false)
      }
    }
    document.addEventListener('keydown', onKeyDown)
    return () => document.removeEventListener('keydown', onKeyDown)
  }, [])

  const updateExistingCategory = React.useCallback(
    (updatedCategories: CategoryEntries) => {
      ;(firebase as any)
        .database()
        .ref(`/supplier_categories/${supplier.id}`)
        .set(Object.fromEntries(updatedCategories))
    },
    [firebase, supplier.id]
  )

  // Add new category to Firebase
  const addNewCategory = React.useCallback(() => {
    const newItemRef = (firebase as any)
      .database()
      .ref(`/supplier_categories/${supplier.id}`)
      .push()

    newItemRef.set({
      title: selectedCategoryTitle,
      order: supplierCategoriesList.length
    })
  }, [firebase, supplier.id, selectedCategoryTitle, supplierCategoriesList])

  React.useEffect(() => {
    if (!isEditing) {
      setSelectedCategoryTitle('')
      setSelectedCategory(null)
    }
  }, [isEditing])

  const onEditCategory = React.useCallback((key, category) => {
    setIsEditing(true)
    setSelectedCategory(key)
    setSelectedCategoryTitle(category.title)
  }, [])

  const onDeleteCategory = React.useCallback(
    index => {
      supplierCategoriesList.splice(index, 1)
      updateExistingCategory(supplierCategoriesList)
    },
    [updateExistingCategory, supplierCategoriesList]
  )

  return (
    <>
      <div>
        <button
          className={`${classes.category} ${classes.button}`}
          onClick={() => {
            setIsEditing(true)
          }}>
          + Add new
        </button>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="categories">
          {provided => (
            <div
              className={classes.root}
              {...provided.droppableProps}
              ref={provided.innerRef}>
              {supplierCategoriesList &&
                supplierCategoriesList.map(([categoryKey, category], index) => (
                  <SupplierCategoryItem
                    categoryKey={categoryKey}
                    index={index}
                    classes={classes}
                    category={category}
                    onEditCategory={onEditCategory}
                    onDeleteCategory={onDeleteCategory}
                    key={categoryKey}
                  />
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {isEditing && (
        <>
          <div className={classes.modalBg} onClick={onModalDismiss} />
          <input
            autoFocus
            className={classes.input}
            value={selectedCategoryTitle}
            onChange={onInputChange}
            onKeyDown={onInputEnter}
          />
        </>
      )}
    </>
  )
}

interface SupplierCategoryItemProps extends WithStyles<typeof styles> {
  categoryKey: string
  index: number
  category: SupplierCategory
  onEditCategory: (key: string, category: SupplierCategory) => void
  onDeleteCategory: (index: number) => void
}

const SupplierCategoryItem = ({
  categoryKey,
  index,
  classes,
  category,
  onEditCategory,
  onDeleteCategory
}: SupplierCategoryItemProps) => {
  return (
    <Draggable key={categoryKey} draggableId={categoryKey} index={index}>
      {provided => (
        <div
          className={classes.category}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          onDoubleClick={() => {
            onEditCategory(categoryKey, category)
          }}>
          {category.title}
          <span
            className={classes.categoryEdit}
            onClick={() => {
              onEditCategory(categoryKey, category)
            }}>
            Edit
          </span>
          <span
            className={`${classes.categoryEdit} ${classes.categoryDelete}`}
            onClick={() => {
              if (window.confirm('are you sure?')) {
                onDeleteCategory(index)
              }
            }}>
            Delete
          </span>
        </div>
      )}
    </Draggable>
  )
}

export default SupplierCategoriesList
