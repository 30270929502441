import { ExtendedAuthInstance } from 'react-redux-firebase'
import { createSelector } from 'reselect'

export const FirebaseAuth = (s: { firebase: { auth: ExtendedAuthInstance } }) =>
  s.firebase.auth

const selectProfile = (s: any) => s.firebase.profile
export const selectIsAdmin = createSelector(
  selectProfile,
  (profile) => profile?.token?.claims?.admin || false
)
