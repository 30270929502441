import { makeStyles, createStyles } from '@material-ui/core'
import moment from 'moment'
import { useRef, useEffect } from 'react'
import { useLocation } from 'react-router'
import ComputerIcon from '@material-ui/icons/Computer'
import MailIcon from '@material-ui/icons/Mail'
import PrintIcon from '@material-ui/icons/Print'
import SmsIcon from '@material-ui/icons/Sms'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import React from 'react'

export const makeTimestampToMoment = (t: number): moment.Moment => {
  try {
    const ifAfterThisIFuckedUp = moment('01.01.2100', 'DD.MM.YYYY')
    const ifBeforeThisIFuckedUp = moment('01.01.2019', 'DD.MM.YYYY')
    const checkMoment = (m: moment.Moment): boolean =>
      m.isValid() &&
      !m.isAfter(ifAfterThisIFuckedUp) &&
      !m.isBefore(ifBeforeThisIFuckedUp)
    let momentifiedTimestamp = moment(t)
    if (checkMoment(momentifiedTimestamp)) return momentifiedTimestamp

    // Moment seems to be invalid, or unix in milliseconds
    momentifiedTimestamp = moment(t, 'X')
    if (checkMoment(momentifiedTimestamp)) return momentifiedTimestamp
    // Moment seems to be invalid, or unix in milliseconds
    momentifiedTimestamp = moment(t, 'x')
    if (checkMoment(momentifiedTimestamp)) return momentifiedTimestamp
    // Jump to Catch
    throw new Error()
  } catch (e) {
    // We majorly fucked up here?? it's neither unix, nor unix in miliseconds? Log error and return today, so UI doesnt crash
    return moment()
  }
}
export const readCSVasText = async (e: any, onLoad: (r: string) => void) => {
  e.preventDefault()
  const reader = new FileReader()
  reader.onload = e => onLoad(e.target.result as string)
  reader.readAsText(e.target.files[0])
}
export const mapToArrayWithID = (object: object) => {
  if (!object) return []
  return Object.entries(object).map(([key, value]) => ({ ...value, id: key }))
}
export const useIsMount = () => {
  const isMountRef = useRef(true)
  useEffect(() => {
    isMountRef.current = false
  }, [])
  return isMountRef.current
}

// A custom hook that builds on useLocation to parse
// the query string for you.
export const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

export const insertUrlParam = (key, value) => {
  if (window.history.pushState) {
    let searchParams = new URLSearchParams(window.location.search)
    if (value) searchParams.set(key, value)
    else searchParams.delete(key)
    let newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      '?' +
      searchParams.toString()
    window.history.pushState({ path: newurl }, '', newurl)
  }
}

export const useSearchQuery = () => {
  const query = useQuery()
  const searchText = query.get('search')
  return {
    onSearchChange: search => insertUrlParam('search', search),
    searchText
  }
}

export const useDeliveryIcons = () => {
  const useStyles = makeStyles(() =>
    createStyles({
      icon: {
        color: 'black',
        fontSize: 16
      }
    })
  )
  const classes = useStyles()
  const Icons = {
    whatsapp: <WhatsAppIcon className={classes.icon} />,
    email: <MailIcon className={classes.icon} />,
    csvemail: <MailIcon className={classes.icon} />,
    phone: <SmsIcon className={classes.icon} />,
    crawler: <ComputerIcon className={classes.icon} />,
    fax: <PrintIcon className={classes.icon} />
  }
  return Icons
}

export const ensureInt = (
  input: string | number,
  fallback: number = 0
): number =>
  // eslint-disable-next-line no-nested-ternary
  input ? (typeof input === 'number' ? input : parseInt(input, 10)) : fallback

export const useTableRef = (deps: React.DependencyList) => {
  const tableRef = useRef()
  useEffect(() => {
    // @ts-ignore
    tableRef?.current?.dataManager?.clearBulkEditChangedRows()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)

  return tableRef
}
