/* eslint-disable react/jsx-no-target-blank */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Input
} from '@material-ui/core'
import Papa from 'papaparse'
import React, { useState } from 'react'
import { readCSVasText } from '../../services/helpers'

interface BasicCsvImporterInterface {
  open: boolean
  onClose: VoidFunction
  currentImport?: {
    currentStep: number
    total: number
    currentObject: any
    errors: number
  }
  additionalControls?: JSX.Element
  onImport: (json: any) => void
  templateHref?: string
}
const BasicCsvImporter = ({
  open,
  onClose,
  currentImport,
  additionalControls,
  onImport,
  templateHref = 'https://hier.link/csvimport'
}: BasicCsvImporterInterface) => {
  const [importerFile, setImporterFile] = useState<null | string>()
  const importCSV = async () => {
    try {
      const json = Papa.parse(importerFile, {
        header: true,
        transform: (v) => {
          if (typeof v === 'string') return v.trim()
          return v
        }
      }).data
      onImport(json)
      setImporterFile(null)
    } catch (e) {}
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>CSV-Import</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Bitte laden Sie eine CSV mit den Produkten hoch (
          <a href={templateHref} target="_blank">
            Vorlage
          </a>
          )
        </DialogContentText>
        <Input
          autoFocus
          margin="dense"
          id="name"
          type="file"
          inputProps={{ accept: 'text/csv' }}
          fullWidth
          onChange={(e) => readCSVasText(e, setImporterFile)}
        />
        {additionalControls}
        {currentImport && (
          <DialogContentText>
            {`Importiere ${currentImport.currentStep} / ${
              currentImport.total
            }: ${currentImport.currentObject?.title || ''}`}
          </DialogContentText>
        )}
        {currentImport?.errors > 0 && (
          <DialogContentText>
            {`Fehlgeschlagen: ${currentImport.errors}`}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Abbrechen
        </Button>
        <Button
          onClick={importCSV}
          color="primary"
          disabled={!importerFile || !!currentImport}>
          Importieren
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default BasicCsvImporter
