import imageCompression from 'browser-image-compression'

export const uploadFile = async (path: string, f: File, firebase: any) => {
  const options = {
    maxSizeMB: 0.6,
    maxWidthOrHeight: 500,
    useWebWorker: true
  }
  const compressedFile = await imageCompression(f, options)
  const file = await firebase.uploadFile(path, compressedFile)
  const metadata = file.uploadTaskSnapshot.metadata
  return `gs://${metadata.bucket}/${metadata.fullPath}`
}

export const deleteFile = async (path: string, firebase: any) => {
  await firebase.deleteFile(path)
}

export const fileUrl = async (path: any, firebase: any) => {
  const ref = await firebase.storage().refFromURL(path)
  const url = await ref.getDownloadURL()
  return url
}
