/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Product } from '@hierfoods/interfaces'
import { CSV, FileMapping, CSVProductDiff, DBFields } from './types'
import LinearProgress from '@material-ui/core/LinearProgress'

import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

export function RenderTable(props: {
  previousState?: Product[] // todo later come up and show diff in table ui
  nextState: Product[]
  section: string
  mapping: FileMapping
}) {
  const headers = Object.values(DBFields)
  const styles = useTableStyles()
  const { t } = useTranslation()
  console.log(props)
  return (
    <div className={styles.root}>
      <table>
        <thead>
          <tr>
            {headers.map((h, i) => {
              return <th key={i}>{t('importer.properties.' + h)}</th>
            })}
          </tr>
        </thead>
        <tbody>
          {props.nextState.map((p, i) => {
            return (
              <tr key={i}>
                {headers.map((h, i) => {
                  return <td key={i}>{p[h]}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
const useTableStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& table': {
        background: '#ffffff8f',
        'border-collapse': 'collapse',
        border: '1px solid #444',
        margin: '10px 0',
        maxWidth: '80vw'
      },
      '& table > thead': {
        background: 'white'
      },
      '& td': {
        border: '1px solid #444'
      },
      '& th': {
        padding: '0 2px',
        textTranform: 'uppercase'
      }
    }
  })
)
export function ReviewStep(props: {
  csv: CSV
  mapping: FileMapping
  diff: CSVProductDiff
  progress: number
  working: boolean
  finished: boolean | Error
}) {
  const styles = useStyles()
  const { diff, progress, working } = props
  const [activeSection, setActiveSection] = React.useState<
    null | 'created' | 'updated' | 'deleted'
  >(null)

  const showSection = (sectionName: string) => {
    return (
      <a
        href="#"
        onClick={e => {
          e.preventDefault()
          if (activeSection === sectionName) return setActiveSection(null)
          return setActiveSection(
            sectionName as 'created' | 'updated' | 'deleted'
          )
        }}>
        show records
      </a>
    )
  }

  return (
    <div className={styles.root}>
      <div className={styles.summary}>
        <div className={styles.summaryLabel}>Import Summary</div>
        <div className={styles.summaryTable}>
          <div className={styles.summaryRow}>
            <div>
              Created
              <div>{showSection('created')}</div>
            </div>
            <div>{diff ? diff.created.length : '...'}</div>
          </div>
          <div className={styles.summaryRow}>
            <div>
              Updated
              <div>{showSection('updated')}</div>
            </div>
            <div>{diff ? diff.updated.length : '...'}</div>
          </div>
          <div className={styles.summaryRow}>
            <div>
              Deleted
              <div>{showSection('deleted')}</div>
            </div>
            <div>{diff ? diff.deleted.length : '...'}</div>
          </div>
        </div>
      </div>
      <div>
        {working && <LinearProgress variant="determinate" value={progress} />}
      </div>
      <div>
        {activeSection && !working && !props.finished && (
          <RenderTable
            mapping={props.mapping}
            nextState={
              activeSection === 'deleted'
                ? diff.deleted
                : diff[activeSection].map(e => e.mapped)
            }
            previousState={[]}
            section="updated"
          />
        )}
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    summary: {
      width: 250,
      margin: 'auto'
    },
    summaryRow: {
      display: 'flex',
      '&  > div:last-child': {
        flex: 1,
        textAlign: 'right'
      },
      '&  > div:first-child': {
        textAlign: 'left'
      },
      '& a': {
        color: 'black'
      }
    },
    summaryTable: {},
    summaryLabel: {
      textAlign: 'center',
      padding: '5px 0',
      fontWeight: 'bold'
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '5px 10px'
    }
  })
)
