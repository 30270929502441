import { Product, ProductColums } from '@hierfoods/interfaces'
import { Button, Checkbox, FormControlLabel } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import CloudUpload from '@material-ui/icons/CloudUpload'
import Delete from '@material-ui/icons/Delete'
import Alert from '@material-ui/lab/Alert'
import Autocomplete, {
  createFilterOptions
} from '@material-ui/lab/Autocomplete'
import { selectSupplier } from '@selectors/supplier'
import {
  selectBulkUnits,
  selectBulkUnitsForAutocomplete,
  selectProductTypes,
  selectProductTypesForAutocomplete,
  selectSingleUnits,
  selectSingleUnitsForAutocomplete,
  selectVAT,
  selectVATForAutocomplete
} from '@selectors/units'
import { deleteFile, uploadFile } from '@services/storage'

import MaterialTable from '@material-table/core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useFirebase, useFirebaseConnect } from 'react-redux-firebase'
import {
  selectCategories,
  selectCategoriesForAutocomplete
} from '../../selectors/category'
import { selectProductsForSupplier } from '../../selectors/product'
import { updateProduct } from '../../services/database'
import {
  makeTimestampToMoment,
  useSearchQuery,
  useTableRef
} from '../../services/helpers'
import BasicCsvImporter from '../basic-importer/BasicCsvImporter'
import ProductImage from '../images/productImage'
import SupplierDropdown from '../supplierDropdown'
import throttle from 'lodash/throttle'
import cloneDeep from 'lodash/cloneDeep'
import {
  selectLastSupplierCategory,
  selectSupplierCategories,
  selectSupplierCategoriesForAutocomplete
} from '@selectors/supplierCategories'
import ExportCsv from '@material-table/exporters/csv'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  headerLine: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 30,
    marginBottom: 30
  },
  imageContainer: {
    position: 'relative',
    display: 'flex',
    minWidth: 50,
    maxHeight: 60,
    minHeight: 40
  },
  imageButtonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0
  },
  tableContainer: {
    maxWidth: '95vw'
  }
})

const filter = createFilterOptions<{ [fieldToUse: string]: string }>()
const ImageEdit = (props: any) => {
  const { image_path, onChange, onRemove } = props
  const classes = useStyles()
  const [selectedImage, setSelectedImage] = useState(undefined)
  const [imageRemoved, setImageRemoved] = useState(false)

  return (
    <div className={classes.imageContainer}>
      {(selectedImage || image_path) && !imageRemoved && (
        <ProductImage
          imagePath={selectedImage || image_path}
          origin={!!selectedImage}
        />
      )}
      <div className={classes.imageButtonsContainer}>
        <IconButton component="label" aria-label="delete" color="secondary">
          <CloudUpload />
          <input
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              onChange(event?.target?.files[0])
              setSelectedImage(URL.createObjectURL(event?.target?.files[0]))
              setImageRemoved(false)
            }}
          />
        </IconButton>
        {(selectedImage || image_path) && !imageRemoved && (
          <IconButton
            component="label"
            aria-label="delete"
            color="secondary"
            onClick={() => {
              onRemove()
              setImageRemoved(true)
            }}>
            <Delete />
          </IconButton>
        )}
      </div>
    </div>
  )
}

const CustomAutoComplete = ({
  defaultValue,
  options,
  row,
  dbPath,
  fieldToUse = 'title',
  inputProps,
  valueTransformer = v => v,
  onNewItem
}: {
  defaultValue: string
  options: { [fieldToUse: string]: any }[]
  row: any
  dbPath: string
  fieldToUse?: string
  inputProps?: any
  valueTransformer?: (v: string) => any
  onNewItem?: (data: any) => any
}) => {
  const firebase = useFirebase()

  const [value, setValue] = useState({ [fieldToUse]: defaultValue })
  const [dataToUpload, setDataToUpload] = useState<{
    key: string
    [fieldToUse: string]: string
  } | null>()
  const [isOpen, setIsOpen] = useState(false)

  const { t } = useTranslation()
  useEffect(() => {
    const uploadData = async (key: string, value: any) => {
      await firebase.set(`${dbPath}/${key}`, value)
    }
    return () => {
      if (dataToUpload?.key) {
        uploadData(dataToUpload.key, {
          ...dataToUpload,
          [fieldToUse]: valueTransformer(dataToUpload[fieldToUse])
        })
      }
    }
  }, [dataToUpload, dbPath, fieldToUse, firebase, valueTransformer])
  const handleKey = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && isOpen) {
      e.stopPropagation()
    }
  }
  const onOpen = useCallback(() => {
    setIsOpen(true)
  }, [])
  const onClose = useCallback(() => {
    setIsOpen(false)
  }, [])
  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option: any) => {
        if (typeof option === 'string') {
          return option
        }
        if (option.inputValue) {
          return option.inputValue
        }
        return option[fieldToUse].toString()
      }}
      style={{ width: 200 }}
      renderInput={params => <TextField {...params} {...inputProps} />}
      value={value}
      freeSolo
      autoSelect
      onOpen={onOpen}
      onClose={onClose}
      onKeyDown={e => handleKey(e)}
      filterOptions={(options, params) => {
        const filtered = filter(options, params)

        if (params.inputValue !== '') {
          const addValue = {
            inputValue: `${params.inputValue} ${t(
              'buttons.add'
            ).toLowerCase()}`,
            [fieldToUse]: params.inputValue
          }
          filtered.push(addValue)
        }
        return filtered
      }}
      onChange={async (_event: any, data: any | null) => {
        if (!data) {
          row.onChange(null)
          return
        }

        if (data.key) {
          row.onChange(data.key)
          setValue({
            [fieldToUse]: data[fieldToUse] as string
          })
          return
        }
        if (data.inputValue) {
          let newValue = {
            [fieldToUse]: data[fieldToUse] as string
          }
          if (onNewItem) {
            newValue = onNewItem(newValue)
          }
          setValue(newValue)
          const key = firebase.database().ref(dbPath).push().key
          setDataToUpload({ key, ...newValue })
          row.onChange(key)
          return
        }
      }}
    />
  )
}
const renderAndHighlightDeleted = (
  row: any,
  field: string,
  map: { [key: string]: any },
  deletedText: string
) =>
  row?.[field]
    ? map?.[row?.[field]] || (
        <p style={{ color: 'red' }}>Gelöschte {deletedText}</p>
      )
    : ''

const lookupFilteringProps = {
  filterComponent: ({ columnDef, onFilterChanged, forwardedRef }) => (
    <TextField
      ref={forwardedRef}
      style={columnDef.type === 'numeric' ? { float: 'right' } : {}}
      type={columnDef.type === 'numeric' ? 'number' : 'search'}
      value={columnDef.tableData.filterValue || ''}
      onChange={event => {
        onFilterChanged(columnDef.tableData.id, event.target.value)
      }}
    />
  ),
  customFilterAndSearch: (term, rowData, columnDef) => {
    const rawData = rowData[columnDef.field]
    const lookupData = columnDef.lookup?.[rawData] || rawData
    return `${lookupData}`.toLowerCase().includes(`${term}`.toLowerCase())
  }
}

const Products = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const firebase = useFirebase()
  const database = (firebase as any).database()

  const [loading, setLoading] = useState(false)
  const [openImporter, setOpenImporter] = useState(false)
  const [currentImport, setCurrentImport] = useState<null | {
    currentStep: number
    total: number
    currentObject: any
    errors: number
  }>()
  const [addMissingTypes, setAddMissingTypes] = useState(false)
  const [addMissingCategories, setAddMissingCategories] = useState(false)
  const [addMissingSupplierCategories, setAddMissingSupplierCategories] =
    useState(false)

  // Alerts
  const [productRemoved, setProductRemoved] = useState(false)
  /* Fetch data */
  const supplier = useSelector(selectSupplier)
  const tableRef = useTableRef([supplier?.id])

  useFirebaseConnect([
    {
      path: 'categories'
    },
    {
      path: 'bulk_units'
    },
    {
      path: 'single_units'
    },
    {
      path: 'product_types'
    },
    {
      path: 'vat'
    },
    {
      path: `products/${supplier?.id}`
    },
    {
      path: `supplier_categories/${supplier?.id}`
    },
    {
      path: `supplier_categories/${supplier?.id}`,
      queryParams: ['orderByChild=order', 'limitToLast=1'],
      storeAs: 'lastSupplierCategory'
    }
  ])

  const { onSearchChange, searchText } = useSearchQuery()

  const lastSupplierCategory = useSelector(selectLastSupplierCategory)

  const supplierCategories = useSelector(selectSupplierCategories)
  const supplierCategoriesForAutocomplete = useSelector(
    selectSupplierCategoriesForAutocomplete
  )

  const categoriesMapped = useSelector(selectCategories)
  const categoriesForAutocomplete = useSelector(selectCategoriesForAutocomplete)
  const allProducts = useSelector(selectProductsForSupplier)

  const firebaseProductsTimestamp = useSelector(
    (s: any) => s.firebase.timestamps[`products/${supplier?.id}`]
  )
  const [currentProductsTimestamp, setCurrentProductsTimestamp] = useState(
    firebaseProductsTimestamp
  )

  const [productsData, setProductsData] = useState([])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledSetProductsData = useCallback(
    throttle(setProductsData, 1000),
    [setProductsData]
  )
  const [hasNewData, setHasNewData] = useState(false)
  // Autocompletes
  const bulkUnitsMapped = useSelector(selectBulkUnits)
  const bulkUnitsForAutocomplete = useSelector(selectBulkUnitsForAutocomplete)
  const singleUnitsMapped = useSelector(selectSingleUnits)
  const singleUnitsForAutocomplete = useSelector(
    selectSingleUnitsForAutocomplete
  )
  const productTypesMapped = useSelector(selectProductTypes)
  const productTypesForAutocomplete = useSelector(
    selectProductTypesForAutocomplete
  )
  const vatsMapped = useSelector(selectVAT)
  const vatsForAutocomplete = useSelector(selectVATForAutocomplete)
  const handleImage = async (product: Product) => {
    // Remove image from storage if product image path set
    if (product.image === 'deleted' && product.image_path) {
      await deleteFile(`products/${supplier.id}/${product.id}`, firebase)
      product.image_path = null
      return
    }

    if (product.image) {
      const file = new File([product.image], product.id, {
        type: (product.image as File).type
      })
      return await uploadFile(`products/${supplier.id}`, file, firebase)
    }

    return product.image_path
  }
  /* Define columns */
  const columns = useMemo(
    () => [
      {
        title: ProductColums.SoftDeleted,
        field: 'soft_deleted',
        type: 'boolean',
        export: true
      },
      {
        hidden: true,
        title: ProductColums.UpdatedAt,
        field: 'updated_at',
        defaultSort: 'desc',
        render: (product: Product) =>
          product?.updated_at && (
            <span>
              {makeTimestampToMoment(product.updated_at).format('DD.MM.YY')}
              <br />
              {makeTimestampToMoment(product.updated_at).format('hh:mm')}
            </span>
          ),
        editable: 'never',
        export: false
      },
      {
        hidden: !searchText,
        export: true,
        title: ProductColums.ID,
        field: 'id',
        editable: 'never'
      },
      {
        title: ProductColums.SupplierProductID,
        field: 'supplier_product_id',
        export: true
      },
      { title: ProductColums.EAN, field: 'ean', export: true },
      {
        title: ProductColums.ProductTypeID,
        field: 'product_type_id',
        lookup: productTypesMapped,
        export: true,
        editComponent: (row: any) => (
          <CustomAutoComplete
            options={productTypesForAutocomplete}
            row={row}
            defaultValue={productTypesMapped?.[row.value] || ''}
            dbPath="product_types"
          />
        ),
        render: (row: any) =>
          renderAndHighlightDeleted(
            row,
            'product_type_id',
            productTypesMapped,
            'ProductTyp'
          ),
        ...lookupFilteringProps
      },

      {
        title: ProductColums.Title,
        field: 'title',
        headerStyle: { minWidth: 300 },
        cellStyle: { minWidth: 300 },
        export: true,
        editComponent: (row: any) => (
          <TextField
            label={t('title')}
            multiline
            rows={2}
            defaultValue={row.value}
            onChange={e => {
              row.onChange(e.target.value)
            }}
            style={{ width: '100%' }}
          />
        )
      },
      {
        title: ProductColums.BulkUnitID,
        field: 'bulk_unit_id',
        lookup: bulkUnitsMapped,
        export: true,
        editComponent: (row: any) => (
          <CustomAutoComplete
            options={bulkUnitsForAutocomplete}
            row={row}
            defaultValue={bulkUnitsMapped?.[row.value] || ''}
            dbPath="bulk_units"
          />
        ),
        render: (row: any) =>
          renderAndHighlightDeleted(
            row,
            'bulk_unit_id',
            bulkUnitsMapped,
            'BulkUnit'
          ),
        ...lookupFilteringProps
      },

      {
        title: ProductColums.BulkAmount,
        field: 'bulk_amount',
        export: true,
        type: 'numeric'
      },
      {
        title: ProductColums.SingleUnitID,
        field: 'single_unit_id',
        export: true,
        lookup: singleUnitsMapped,
        editComponent: (row: any) => (
          <CustomAutoComplete
            options={singleUnitsForAutocomplete}
            row={row}
            defaultValue={singleUnitsMapped?.[row.value] || ''}
            dbPath="single_units"
          />
        ),

        render: (row: any) =>
          renderAndHighlightDeleted(
            row,
            'single_unit_id',
            singleUnitsMapped,
            'SingleUnit'
          ),
        ...lookupFilteringProps
      },
      {
        title: ProductColums.Weight,
        field: 'weight',
        export: true,
        type: 'numeric'
      },
      {
        title: ProductColums.Volume,
        field: 'volume',
        export: true,
        type: 'numeric'
      },
      {
        title: ProductColums.BulkPrice,
        field: 'bulk_price',
        export: true,
        type: 'numeric'
      },
      {
        title: ProductColums.SellingPrice,
        field: 'selling_price',
        export: true,
        type: 'numeric'
      },
      {
        title: ProductColums.VAT_ID,
        field: 'vat_id',
        export: true,
        lookup: vatsMapped,
        editComponent: (row: any) => (
          <CustomAutoComplete
            options={vatsForAutocomplete}
            row={row}
            defaultValue={vatsMapped?.[row.value] || ''}
            dbPath="vat"
            fieldToUse="value"
            inputProps={{ type: 'number' }}
            valueTransformer={v => parseFloat(v.replace(',', '.'))}
          />
        ),
        render: (row: any) =>
          renderAndHighlightDeleted(row, 'vat_id', vatsMapped, 'VAT'),
        ...lookupFilteringProps
      },
      {
        export: true,
        title: ProductColums.DefaultPrice,
        field: 'default_price',
        type: 'numeric'
      },
      {
        title: ProductColums.SupplierCategoryID,
        field: 'supplier_category_id',
        lookup: supplierCategories,
        editComponent: (row: any) => {
          return (
            <CustomAutoComplete
              options={supplierCategoriesForAutocomplete}
              row={row}
              defaultValue={supplierCategories?.[row.value] || ''}
              dbPath={`/supplier_categories/${supplier.id}`}
              onNewItem={data => {
                return {
                  order:
                    lastSupplierCategory &&
                    (lastSupplierCategory as any).order > -1 &&
                    Number.isFinite((lastSupplierCategory as any).order)
                      ? (lastSupplierCategory as any).order + 1
                      : 0,
                  ...data
                }
              }}
            />
          )
        },
        render: (row: any) =>
          renderAndHighlightDeleted(
            row,
            'supplier_category_id',
            supplierCategories,
            'Supplier Kategorie'
          ),
        ...lookupFilteringProps
      },
      {
        title: ProductColums.CategoryID,
        field: 'category_id',
        export: true,
        lookup: categoriesMapped,
        editComponent: (row: any) => (
          <CustomAutoComplete
            options={categoriesForAutocomplete}
            row={row}
            defaultValue={categoriesMapped?.[row.value] || ''}
            dbPath="categories"
          />
        ),
        render: (row: any) =>
          renderAndHighlightDeleted(
            row,
            'category_id',
            categoriesMapped,
            'Kategorie'
          ),
        ...lookupFilteringProps
      },
      {
        title: ProductColums.Fee,
        field: 'fee',
        export: true,
        type: 'numeric'
      },
      {
        title: ProductColums.ImagePath,
        field: 'image_path',
        export: true,
        sorting: false,
        filtering: false,
        render: (row: any) =>
          row.image_path && <ProductImage imagePath={row.image_path} />,
        editComponent: (row: any) => (
          <ImageEdit
            image_path={row.rowData.image_path}
            onChange={(path: any) => {
              row.onRowDataChange({ ...row.rowData, image: path })
            }}
            onRemove={() => {
              row.onRowDataChange({ ...row.rowData, image: 'deleted' })
            }}
          />
        )
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      productTypesMapped,
      bulkUnitsMapped,
      singleUnitsMapped,
      vatsMapped,
      categoriesMapped,
      productTypesForAutocomplete,
      t,
      bulkUnitsForAutocomplete,
      singleUnitsForAutocomplete,
      vatsForAutocomplete,
      categoriesForAutocomplete,
      supplier,
      supplierCategories,
      lastSupplierCategory,
      supplierCategoriesForAutocomplete
    ]
  )

  /* Define table options */
  const localization = useMemo(
    () => ({
      body: {
        emptyDataSourceMessage: t('messages.noData'),
        addTooltip: t('buttons.add'),
        deleteTooltip: t('buttons.delete'),
        editTooltip: t('buttons.edit'),
        editRow: {
          deleteText: t('messages.delete'),
          cancelTooltip: t('buttons.cancel'),
          saveTooltip: t('buttons.save')
        }
      },
      pagination: {
        labelDisplayedRows: t('pagination.label'),
        labelRowsSelect: t('pagination.rows'),
        labelRowsPerPage: t('pagination.perPage'),
        firstAriaLabel: t('pagination.first'),
        firstTooltip: t('pagination.first'),
        previousAriaLabel: t('pagination.previous'),
        previousTooltip: t('pagination.previous'),
        nextAriaLabel: t('pagination.next'),
        nextTooltip: t('pagination.next'),
        lastAriaLabel: t('pagination.last'),
        lastTooltip: t('pagination.last')
      },
      toolbar: {
        searchTooltip: t('buttons.search'),
        searchPlaceholder: t('buttons.search')
      }
    }),
    [t]
  )

  const options = {
    pageSize: 10,
    pageSizeOptions: [10, 25, 50, 100],
    addRowPosition: 'first',
    draggable: false,
    exportButton: true,
    exportAllData: true,
    columnsButton: true,
    searchText,
    filtering: true,
    hideFilterIcons: true,
    exportMenu: [
      {
        label: 'Export CSV',
        exportFunc: (cols, datas) => ExportCsv(cols, datas, 'productsExport')
      }
    ]
  }

  const onRowUpdate = (product: Product) => {
    return new Promise(async resolve => {
      product.image_path = await handleImage(product)

      await updateProduct(supplier, product, firebase)
      resolve(true)
    })
  }
  const onBulkUpdate = async (
    changes: Record<number, { oldData: Product; newData: Product }>
  ) => {
    setLoading(true)
    for await (const change of Object.values(changes)) {
      await onRowUpdate(change.newData)
    }
    setLoading(false)
  }

  useEffect(() => {
    // Only update Products if we are not currently editing
    setTimeout(() => {
      if (allProducts && supplier) {
        if (
          // @ts-ignore
          tableRef.current?.dataManager?.bulkEditOpen === false &&
          // @ts-ignore
          tableRef.current?.state?.lastEditingRow === undefined &&
          !openImporter &&
          !loading
        ) {
          throttledSetProductsData(cloneDeep(allProducts))
          setHasNewData(false)
          setCurrentProductsTimestamp(firebaseProductsTimestamp)
        } else if (!hasNewData)
          setHasNewData(currentProductsTimestamp !== firebaseProductsTimestamp)
      }
    }, 300)
  }, [
    allProducts,
    currentProductsTimestamp,
    firebaseProductsTimestamp,
    hasNewData,
    loading,
    openImporter,
    supplier,
    throttledSetProductsData
  ])
  const importCSV = async (json: any) => {
    try {
      const getValue = (obj: any, key: string) => {
        if (!obj[key]) return null
        if (typeof obj[key] === 'string' && obj[key].length === 0) return null
        return obj[key]
      }
      const mapped = json.map(
        (j: any) =>
          ({
            supplier_product_id: getValue(j, ProductColums.SupplierProductID),
            ean: getValue(j, ProductColums.EAN)
              ? getValue(j, ProductColums.EAN).toString()
              : null,
            bulk_unit: getValue(j, ProductColums.BulkUnitID),
            id: getValue(j, ProductColums.ID),
            bulk_amount: getValue(j, ProductColums.BulkAmount),
            single_unit: getValue(j, ProductColums.SingleUnitID),
            category: getValue(j, ProductColums.CategoryID),
            supplierCategory: getValue(j, ProductColums.SupplierCategoryID),
            bulk_price: getValue(j, ProductColums.BulkPrice),
            fee: getValue(j, ProductColums.Fee),
            selling_price: getValue(j, ProductColums.SellingPrice),
            vat: getValue(j, ProductColums.VAT_ID),
            title: getValue(j, ProductColums.Title),
            weight: getValue(j, ProductColums.Weight),
            volume: getValue(j, ProductColums.Volume),
            product_type: getValue(j, ProductColums.ProductTypeID),
            soft_deleted: getValue(j, ProductColums.SoftDeleted),
            image_path: getValue(j, ProductColums.ImagePath),
            default_price: getValue(j, ProductColums.DefaultPrice),
            bulk_unit_id: null,
            vat_id: null,
            single_unit_id: null,
            product_type_id: null,
            category_id: null,
            updated_at: Date.now()
          } as any)
      )
      /* replace fields with ids */
      const newTypes = {}
      const newCategories = {}
      for await (const m of mapped) {
        if (m.bulk_unit) {
          const match = bulkUnitsForAutocomplete.find(
            b => b.title.toLowerCase() === m.bulk_unit.toLowerCase()
          )
          if (match) m.bulk_unit_id = match.key
        }
        if (m.single_unit) {
          const match = singleUnitsForAutocomplete.find(
            b => b.title.toLowerCase() === m.single_unit.toLowerCase()
          )
          if (match) m.single_unit_id = match.key
        }
        if (m.supplierCategory) {
          const match = supplierCategoriesForAutocomplete.find(
            b => b.title.toLowerCase() === m.supplierCategory.toLowerCase()
          )
          if (match) m.supplier_category_id = match.key
          else if (addMissingSupplierCategories) {
            const uniqueKey = `${supplier?.id}/${m.supplierCategory}`
            if (!newCategories[uniqueKey]) {
              const newKey = await firebase
                .database()
                .ref(`/supplier_categories/${supplier.id}`)
                .push({
                  title: m.supplierCategory,
                  order:
                    lastSupplierCategory &&
                    (lastSupplierCategory as any).order > -1 &&
                    Number.isFinite((lastSupplierCategory as any).order)
                      ? (lastSupplierCategory as any).order + 1
                      : 0
                })
              newCategories[uniqueKey] = newKey.key
            }
            m.supplier_category_id = newCategories[uniqueKey]
          }
        }
        if (m.category) {
          const match = categoriesForAutocomplete.find(
            b => b.title.toLowerCase() === m.category.toLowerCase()
          )
          if (match) m.category_id = match.key
          else if (addMissingCategories) {
            if (!newCategories[m.category]) {
              const newKey = await firebase
                .database()
                .ref('categories')
                .push({ title: m.category })
              newCategories[m.category] = newKey.key
            }
            m.category_id = newCategories[m.category]
          }
        }
        if (m.vat) {
          const match = vatsForAutocomplete.find(
            b => parseFloat(b.value) === parseFloat(m.vat)
          )
          if (match) m.vat_id = match.key
        }
        if (m.product_type) {
          const match = productTypesForAutocomplete.find(
            b => b.title.toLowerCase() === m.product_type.toLowerCase()
          )
          if (match) m.product_type_id = match.key
          else if (addMissingTypes) {
            if (!newTypes[m.product_type]) {
              const newKey = await firebase
                .database()
                .ref('product_types')
                .push({ title: m.product_type })
              newTypes[m.product_type] = newKey.key
            }
            m.product_type_id = newTypes[m.product_type]
          }
        }
      }
      for await (const imp of mapped) {
        setCurrentImport(c => ({
          currentStep: (c?.currentStep || 0) + 1,
          total: mapped.length,
          currentObject: imp,
          errors: c?.errors || 0
        }))
        let id = imp.id
        if (!id)
          id = firebase.database().ref(`/products/${supplier.id}`).push().key
        await firebase
          .database()
          .ref(`/products/${supplier.id}/${id}`)
          .update({
            ...imp,
            bulk_unit: null,
            single_unit: null,
            category: null,
            vat: null,
            product_type: null
          })
      }
      setCurrentImport(null)

      setOpenImporter(false)
      setAddMissingTypes(false)
      setAddMissingCategories(false)
      setAddMissingSupplierCategories(false)
    } catch (e) {
      console.log(e)
      setCurrentImport(null)

      setOpenImporter(false)
      setAddMissingTypes(false)
      setAddMissingCategories(false)
      setAddMissingSupplierCategories(false)
      alert('Es ist ein Fehler aufgetreten. Überprüfen Sie Ihre CSV Datei')
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.headerLine}>
        <SupplierDropdown />
        {hasNewData && supplier && !loading && (
          <Button
            onClick={() => {
              setTimeout(() => {
                setProductsData(cloneDeep(allProducts))
                setHasNewData(false)
                setCurrentProductsTimestamp(firebaseProductsTimestamp)
              }, 0)
            }}
            variant="contained"
            color="secondary">
            Neue Daten verfügbar. Jetzt laden
          </Button>
        )}
      </div>
      <BasicCsvImporter
        open={openImporter}
        onClose={() => setOpenImporter(false)}
        additionalControls={
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={addMissingTypes}
                  onChange={e => setAddMissingTypes(e.target.checked)}
                  name="addMissingTypes"
                />
              }
              label="Fehlende Typen hinzufügen"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={addMissingSupplierCategories}
                  onChange={e =>
                    setAddMissingSupplierCategories(e.target.checked)
                  }
                  name="addMissingSupplierCategories"
                />
              }
              label="Fehlende Supplier Kategorien hinzufügen"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={addMissingCategories}
                  onChange={e => setAddMissingCategories(e.target.checked)}
                  name="addMissingCategories"
                />
              }
              label="Fehlende Kategorien hinzufügen"
            />
          </>
        }
        onImport={importCSV}
        currentImport={currentImport}
      />
      {supplier && (
        <div className={classes.tableContainer}>
          <MaterialTable
            actions={[
              {
                icon: 'cloud_upload',
                isFreeAction: true,
                tooltip: t('productsList.importCsv.action'),
                onClick: (event, rowData) => {
                  setOpenImporter(true)
                }
              }
            ]}
            isLoading={loading}
            title={t('products')}
            columns={columns as any}
            data={productsData}
            localization={localization}
            options={options as any}
            editable={{
              onBulkUpdate,
              onRowAdd: product =>
                new Promise(async resolve => {
                  const key = database.ref().child('products').push().key
                  product.id = key
                  product.created_at = Date.now()
                  product.image_path = await handleImage(product)
                  await updateProduct(supplier, product, firebase)
                  resolve(true)
                }),
              onRowUpdate,
              onRowDelete: product =>
                new Promise(async resolve => {
                  await firebase.remove(`products/${supplier.id}/${product.id}`)
                  if (product.image_path) {
                    firebase.deleteFile(`products/${supplier.id}/${product.id}`)
                  }
                  setProductRemoved(true)
                  resolve(true)
                })
            }}
            tableRef={tableRef}
            onSearchChange={onSearchChange}
          />
        </div>
      )}
      <Snackbar
        open={productRemoved}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => {
          setProductRemoved(false)
        }}>
        <Alert severity="info">{t('messages.productRemoved')}</Alert>
      </Snackbar>
      <Snackbar
        open={!supplier}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="warning">{t('messages.selectSupplier')}</Alert>
      </Snackbar>
    </div>
  )
}
export default Products
