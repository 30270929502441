import * as React from 'react'
import OrderList from '../../components/lists/orderList'

const ArchivedOrders = () => {
  // @ts-ignore
  return (
    <OrderList
      path={'archived_orders'}
      hideSendAgain
      showExporter
      limitQueryResults
    />
  )
}

export default ArchivedOrders
