import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { FirebaseAuth, selectIsAdmin } from '@selectors/firebaseAuth'
import React from 'react'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import { useSelector } from 'react-redux'
import { isEmpty, useFirebase } from 'react-redux-firebase'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { DialogProvider } from 'use-material-ui-dialog'
import CustomDrawer from './components/drawer'
import Whatsapp from './components/whatsapp'
import Dashboard from './screens/dashboard'
import Orders from '@screens/orders'
import Suppliers from '@screens/supplier'
import Favorites from '@screens/favorites'
import Merchants from '@screens/merchants'
import MerchantSupplier from '@screens/merchantSupplier'
import ArchivedOrders from '@screens/archivedOrders'
import Devices from '@screens/devices'
import SupplierLogins from '@screens/supplierLogins'
import Prices from '@screens/prices'
import Importer from '@screens/importer'
import SupplierCategories from '@screens/supplierCategories'
import Billing from './components/billing'

const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menu: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    root: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: process.env.REACT_APP_BACKGROUNDCOLOR
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      marginLeft: -drawerWidth
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 0
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end'
    }
  })
)

const App = () => {
  const classes = useStyles()
  const firebase = useFirebase()
  const authenticated = useSelector(FirebaseAuth)
  const isAdmin = useSelector(selectIsAdmin)
  const uiConfig = {
    signInFlow: 'popup',
    signInOptions: [
      {
        provider: (firebase.auth as any).EmailAuthProvider.PROVIDER_ID
      }
    ],
    callbacks: {
      signInSuccessWithAuthResult: () => false
    }
  }
  return (
    <div className={classes.root}>
      {!isEmpty(authenticated) ? (
        <DialogProvider>
          <Router>
            <CustomDrawer>
              {isAdmin ? (
                <Switch>
                  <Route path="/whatsapp">
                    <Whatsapp />
                  </Route>
                  <Route path="/billing">
                    <Billing />
                  </Route>
                  <Route path="/import">
                    <Importer />
                  </Route>

                  <Route path="/products">
                    <Dashboard />
                  </Route>
                  <Route path="/prices">
                    <Prices />
                  </Route>
                  <Route path="/suppliers">
                    <Suppliers />
                  </Route>
                  <Route path="/favorites">
                    <Favorites />
                  </Route>
                  <Route path="/merchantsupplier">
                    <MerchantSupplier />
                  </Route>
                  <Route path="/merchants">
                    <Merchants />
                  </Route>
                  <Route path="/archivedorders">
                    <ArchivedOrders />
                  </Route>
                  <Route path="/devices">
                    <Devices />
                  </Route>
                  <Route path="/supplierLogins">
                    <SupplierLogins />
                  </Route>
                  <Route path="/supplier_categories">
                    <SupplierCategories />
                  </Route>
                  <Route path="/">
                    <Orders />
                  </Route>
                </Switch>
              ) : (
                <div>Keine Admin-Berechtigungen</div>
              )}
            </CustomDrawer>
          </Router>
        </DialogProvider>
      ) : (
        <StyledFirebaseAuth
          uiConfig={uiConfig}
          firebaseAuth={firebase.auth()}
        />
      )}
    </div>
  )
}

export default App
