import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'

import { CSV, FileMapping, DBFields } from './types'
import { revGet, getStrategy } from './utils'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    optionContainer: {
      padding: '5px 3px'
    },
    table: {
      display: 'table',
      width: '100%',
      borderCollapse: 'collapse'
    },
    thead: {
      '& th': {
        fontWeight: 'normal',
        textAlign: 'left',
        background: 'white',
        padding: '5px 3px',
        borderTop: '1px solid #ddd',
        borderBottom: '1px solid #ddd'
      }
    },
    tbody: {
      '& td': {
        padding: '5px 3px',
        borderBottom: '1px solid #ddd'
      }
    }
  })
)

export function MappingStep(props: {
  csv: CSV
  mapping: FileMapping
  setMapping: (mapping: FileMapping) => void
}) {
  const { csv, mapping, setMapping } = props
  const fields = csv.meta.fields || []
  const classes = useStyles()
  const { t } = useTranslation()
  const strategy = getStrategy(mapping)
  return (
    <div>
      <h3>We’ve matched the columns in your file to Hier properties.</h3>
      <table className={classes.table}>
        <thead className={classes.thead}>
          <tr>
            <th>Lieferanten Bezeichnung</th>
            <th>VORSCHAU </th>
            <th>HIER PROPERTY</th>
          </tr>
        </thead>
        <tbody className={classes.tbody}>
          {fields.map((fieldName, i) => {
            return (
              <tr
                key={i}
                style={{
                  background: revGet(mapping.fields, fieldName)
                    ? '#8fbc8f33'
                    : 'transparent'
                }}>
                <td>{fieldName}</td>
                <td>
                  {csv.data
                    .slice(0, 2)
                    .map((el) => el[fieldName])
                    .map((fieldValue, i) => (
                      <div key={i}>{fieldValue}</div>
                    ))}
                </td>
                <td>
                  <select
                    title={
                      strategy.disableReMapping
                        ? 'Remappign Disabled for this template'
                        : ''
                    }
                    disabled={strategy.disableReMapping}
                    value={revGet(mapping.fields, fieldName)}
                    onChange={(e) => {
                      const value = e.target.value
                      setMapping({
                        ...mapping,
                        fields: {
                          ...mapping.fields,
                          [value]: fieldName
                        }
                      })
                    }}>
                    <option value={undefined}>
                      choose a property(optional)
                    </option>
                    {Object.values(DBFields).map((dbFieldName, i) => {
                      return (
                        <option key={i} value={dbFieldName}>
                          {t('importer.properties.' + dbFieldName)}
                        </option>
                      )
                    })}
                  </select>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
