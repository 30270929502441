/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import {
  Supplier,
  MerchantRelationShipForSupplier,
  Product
} from '@hierfoods/interfaces'

import { useFirebase, useFirebaseConnect } from 'react-redux-firebase'
import {
  selectSupplier,
  selectMerchantRelationshipsForSupplier
} from '@selectors/supplier'
import { selectProductsForSupplier } from '@selectors/product'
import { selectSupplierCategoryItems } from '@selectors/supplierCategories'

import { createStyles, makeStyles, Theme } from '@material-ui/core'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import { Snackbar } from '@material-ui/core'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'

import { useSelector } from 'react-redux'
import {
  selectBulkUnitsForAutocomplete,
  selectProductTypesForAutocomplete,
  selectSingleUnitsForAutocomplete,
  selectVATForAutocomplete
} from '@selectors/units'
import { selectCategoriesForAutocomplete } from '../../selectors/category'

import SupplierDropdown from '../supplierDropdown'

import { CSV, FileMapping, CSVProductDiff, DBFields } from './types'
import {
  updateRecords,
  computeDiff,
  importCSV,
  validateInputSheet
} from './utils'

import { MappingStep } from './mapping'
import { UploadStep } from './upload'
import { ReviewStep } from './review'
function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

enum Steps {
  PREPARE = 'PREPARE & UPLOAD',
  MAPPING = 'MAP YOUR DATA',
  IMPORT = 'IMPORT'
}
const steps = [Steps.PREPARE, Steps.MAPPING, Steps.IMPORT]

export function ImporterComponent() {
  const classes = useStyles()
  const supplier = useSelector(selectSupplier)
  return (
    <div className={classes.root}>
      <div className={classes.supplierPicker}>
        <SupplierDropdown />
      </div>
      {supplier ? <ImporterDataManager /> : <p>Please select a supplier</p>}
    </div>
  )
}

export function ImporterDataManager() {
  const supplier = useSelector(selectSupplier)
  const allProducts = useSelector(selectProductsForSupplier)
  const products = allProducts || []

  const categoriesMapped = useSelector(selectCategoriesForAutocomplete)
  const bulkUnitsMapped = useSelector(selectBulkUnitsForAutocomplete)
  const singleUnitsMapped = useSelector(selectSingleUnitsForAutocomplete)
  const productTypesMapped = useSelector(selectProductTypesForAutocomplete)
  const vatsMapped = useSelector(selectVATForAutocomplete)
  const merchantSuppliersMapped: MerchantRelationShipForSupplier[] =
    useSelector(selectMerchantRelationshipsForSupplier)
  console.log('merchantSuppliersMapped', merchantSuppliersMapped)
  const supplierCategoriesItems = useSelector(selectSupplierCategoryItems)

  useFirebaseConnect([
    {
      path: 'categories'
    },
    {
      path: 'bulk_units'
    },
    {
      path: 'single_units'
    },
    {
      path: 'product_types'
    },
    {
      path: 'vat'
    },
    {
      path: `products/${supplier?.id}`
    },
    {
      path: `merchant_suppliers`
    },
    {
      path: `supplier_categories/${supplier?.id}`
    }
  ])
  const [mapping, setMapping] = React.useState<FileMapping | null>(null)
  React.useEffect(() => {
    if (
      vatsMapped &&
      productTypesMapped &&
      singleUnitsMapped &&
      bulkUnitsMapped &&
      categoriesMapped &&
      merchantSuppliersMapped
    ) {
      setMapping({
        fields: {},
        deleteUnmatched: false,
        supplier,
        supplierMerchantsRelationships: merchantSuppliersMapped,
        connections: {
          [DBFields.VAT]: { items: vatsMapped },
          [DBFields.PRODUCT_TYPE]: { items: productTypesMapped },
          [DBFields.SINGLE_UNIT]: { items: singleUnitsMapped },
          [DBFields.BULK_UNIT]: { items: bulkUnitsMapped },
          [DBFields.CATEGORY]: { items: categoriesMapped },
          [DBFields.SUPPLIER_CATEGORY]: {
            items: supplierCategoriesItems,
            refName: `supplier_categories/${supplier?.id}`,
            propGenerator: (
              partialItem: Record<string, any>,
              existingRecords: Record<string, any>[]
            ) => {
              return {
                order:
                  (Math.max.apply(
                    null,
                    existingRecords.map(el => el.order || 0)
                  ) || 0) + 1
              }
            }
          }
        }
      })
    }
  }, [
    vatsMapped,
    productTypesMapped,
    singleUnitsMapped,
    bulkUnitsMapped,
    categoriesMapped,
    merchantSuppliersMapped,
    supplierCategoriesItems,
    supplier?.id
  ])
  return mapping ? (
    <Importer
      products={products}
      mapping={mapping}
      setMapping={setMapping}
      supplier={supplier}
    />
  ) : null
}
export function Importer(props: {
  mapping: FileMapping
  supplier: Supplier
  setMapping: (mapping: FileMapping) => void
  products: Product[]
}) {
  const { mapping, setMapping, products, supplier } = props

  const firebase = useFirebase()
  const classes = useStyles()
  const [activeStep, setActiveStep] = React.useState(0)
  const [csv, setCSV] = React.useState<CSV | undefined>()
  const [csvFile, setCSVFile] = React.useState<File | undefined>()
  const [working, setWorking] = React.useState(false)
  const [diff, setDiff] = React.useState<CSVProductDiff | undefined>()
  const [finished, setFinished] = React.useState<boolean | Error>(false)
  const [progress, setProgress] = React.useState(0)

  const handleNext = async () => {
    if (activeStep === 0) {
      setFinished(false)
      const csv = await importCSV(mapping, csvFile)
      try {
        const validated = await validateInputSheet(mapping, csv)
        if (validated) {
          setCSV(csv)
          setActiveStep(activeStep + 1)
        } else {
          window.alert('Validation Failed')
        }
      } catch (e) {
        window.alert(e.message)
      }
    }
    if (activeStep === 1) {
      const diff = await computeDiff(products, csv.data, mapping)
      console.log('diff', diff)
      setDiff(diff)
      setActiveStep(activeStep + 1)
    }
    if (activeStep === 2) {
      setWorking(true)
      try {
        await updateRecords(
          firebase,
          supplier,
          mapping,
          diff,
          products,
          setProgress
        )
        setFinished(true)
      } catch (e) {
        console.error(e)
        setFinished(e)
      } finally {
        setWorking(false)
        setCSV(undefined)
        setDiff(undefined)
        setActiveStep(0)
      }
    }
  }

  const handleBack = () => {
    setWorking(false)
    setFinished(false)
    activeStep !== 0 && setActiveStep(prevActiveStep => prevActiveStep - 1)
  }
  const setDeltaMapping = (deltaMapping: Partial<FileMapping>) => {
    setMapping({
      ...mapping,
      ...deltaMapping
    })
  }

  return (
    <React.Fragment>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          return (
            <Step key={index}>
              <StepLabel>{label}</StepLabel>
            </Step>
          )
        })}
      </Stepper>
      <div className={classes.buttons}>
        <Button disabled={activeStep === 0 || working} onClick={handleBack}>
          Back
        </Button>
        <Button
          disabled={(() => {
            if (working) return true
            if (activeStep === 0) {
              return !csvFile || !mapping || !mapping.preProcessingStratergy
            }
            if (activeStep === 1) {
              return Object.keys(mapping.fields).length === 0
            }
          })()}
          variant="contained"
          color="primary"
          onClick={handleNext}>
          {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
        </Button>
      </div>
      <div>
        {
          <div className={classes.instructions}>
            {(() => {
              const step = activeStep
              if (step === 0) {
                return (
                  <UploadStep
                    {...{
                      mapping,
                      setMapping: setDeltaMapping
                    }}
                    csv={csvFile}
                    setCSVFile={csv => {
                      console.log('setting csv', csv)
                      setCSVFile(csv)
                    }}
                  />
                )
              } else if (step === 1) {
                console.log(csv)
                return (
                  <MappingStep
                    {...{
                      csv,
                      mapping,
                      setMapping: setDeltaMapping
                    }}
                  />
                )
              } else if (step === 2) {
                return (
                  <ReviewStep
                    {...{ csv, mapping, finished, working, diff, progress }}
                  />
                )
              }
            })()}
          </div>
        }
      </div>
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          open={finished === true || finished instanceof Error}
          autoHideDuration={6000}>
          {finished && finished instanceof Error ? (
            <Alert severity="error">{finished.message || 'Unkown Error'}</Alert>
          ) : (
            <Alert severity="success">Import Finished</Alert>
          )}
        </Snackbar>
      </div>
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: '700px',
      width: '100%',
      margin: '0 auto'
    },
    supplierPicker: {
      padding: '7px 0'
    },
    buttons: {
      padding: 10
    },
    instructions: {
      padding: '10px'
    },
    loader: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2)
      }
    },
    orderPrice: {
      fontWeight: 'bold'
    },
    icon: {
      color: 'black',
      fontSize: 16
    },
    selectorContainer: {
      flexDirection: 'row',
      flex: 1,
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      paddingBottom: 20
    }
  })
)
