/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  createStyles,
  makeStyles,
  Snackbar,
  TextField,
  Theme
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { selectSupplier } from '@selectors/supplier'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useFirebase } from 'react-redux-firebase'
import SupplierDropdown from './supplierDropdown'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    headerLine: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 30,
      marginBottom: 30
    }
  })
)

const SupplierLogin = () => {
  const { t } = useTranslation()
  const firebase = useFirebase()
  const classes = useStyles()
  const [error, setError] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [successVisible, setSuccessVisible] = useState(false)

  const supplier = useSelector(selectSupplier)
  const setData = async () => {
    setError('')
    if (!(email?.length > 0)) {
      setError('Bitte Email eingeben')
      return
    }
    if (!(password?.length > 0)) {
      setError('Bitte Passwort eingeben')
      return
    }
    const createSupplierUser = (firebase as any)
      .functions()
      .httpsCallable('defaults-createSupplierUser')
    try {
      const { data } = await createSupplierUser({
        email,
        password,
        supplierID: supplier
      })
      if (data === 403) setError('Nicht Berechtigt für diese Aktion')
      else if (data === 409) setError('Email existiert bereits')
      else if (data === 404) setError('Supplier nicht gefunden')
      else if (data === 500) setError('Es ist ein Fehler aufgetreten')
      else setSuccessVisible(true)
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <div className={classes.root}>
      <div className={classes.headerLine}>
        <SupplierDropdown />
      </div>
      {!supplier ? (
        'Bitte Supplier auswählen'
      ) : (
        <>
          <TextField
            autoFocus
            fullWidth
            margin="dense"
            value={email}
            onChange={(event) => {
              setEmail(event.target.value)
            }}
            label={'Email-Adresse'}
          />
          <TextField
            autoFocus
            fullWidth
            margin="dense"
            value={password}
            onChange={(event) => {
              setPassword(event.target.value)
            }}
            label={'Passwort'}
          />

          <Button color="primary" onClick={setData}>
            {t('buttons.send')}
          </Button>
          <Snackbar
            autoHideDuration={2000}
            open={error?.length > 0}
            onClose={() => setError('')}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="error">{error}</Alert>
          </Snackbar>
          <Snackbar
            autoHideDuration={2000}
            open={successVisible}
            onClose={() => setSuccessVisible(false)}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="success">Zugangsdaten Erfolgreich gesetzt</Alert>
          </Snackbar>
        </>
      )}
    </div>
  )
}
export default SupplierLogin
