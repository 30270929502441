import { Merchant } from '@hierfoods/interfaces'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { setMerchant } from '@redux/merchant'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useFirebaseConnect } from 'react-redux-firebase'
import { selectMerchant, selectMerchantList } from '@selectors/merchant'
import { insertUrlParam, useQuery } from '../services/helpers'

const MerchantDropdown = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  useFirebaseConnect(['merchants'])
  const merchant = useSelector(selectMerchant)
  const merchants = useSelector(selectMerchantList)

  const query = useQuery()
  const queryMerchant = query.get('merchantID')
  React.useEffect(() => {
    // First render, check if we have a url query set
    if (merchants) {
      if (queryMerchant && !merchant)
        dispatch(setMerchant(merchants.find(s => s.id === queryMerchant)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchants, merchant])

  React.useEffect(() => {
    // When merchant changes, change url search
    if (merchant) insertUrlParam('merchantID', merchant.id)
  }, [merchant])

  // If we have a query and no merchant yet, show Loading
  if (queryMerchant && !merchant) return <div>Loading Merchant</div>
  return (
    <Autocomplete
      options={merchants}
      getOptionLabel={merchant => `${merchant.name} (${merchant.id})`}
      style={{ width: 400 }}
      renderInput={params => <TextField {...params} label={t('merchant')} />}
      onChange={(event: any, merchant: Merchant | null) => {
        dispatch(setMerchant(merchant))
      }}
      value={merchant}
      getOptionSelected={(option, value) => option.id === value.id}
    />
  )
}

export default MerchantDropdown
