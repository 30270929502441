import { Product, Supplier } from '@hierfoods/interfaces'
import { ExtendedFirebaseInstance } from 'react-redux-firebase'
import { uploadFile } from './storage'
import moment from 'moment'

export const updateProduct = async (
  supplier: Supplier,
  product: Product,
  firebase: ExtendedFirebaseInstance
) => {
  return firebase.update(`products/${supplier.id}/${product.id}`, {
    title: product.title || null,
    bulk_amount: product.bulk_amount || null,
    bulk_unit: product.bulk_unit || null,
    bulk_unit_id: product.bulk_unit_id || null,
    single_unit_id: product.single_unit_id || null,
    category_id: product.category_id || null,
    supplier_category_id: product.supplier_category_id || null,
    product_type: product.product_type || null,
    product_type_id: product.product_type_id || null,
    bulk_price: product.bulk_price || null,
    fee: product.fee || null,
    selling_price: product.selling_price || null,
    default_price: product.default_price || null,
    single_unit: product.single_unit || null,
    image_path: product.image_path || null,
    created_at: product.created_at || null,
    supplier_product_id: product.supplier_product_id || null,
    ean: product.ean || null,
    soft_deleted: product.soft_deleted || null,
    weight: product.weight || null,
    volume: product.volume || null,
    vat_id: product.vat_id || null
  })
}

export const updateSupplier = async (supplier: Supplier, firebase: any) => {
  if (!supplier.icon_path) {
    /* we have no icon path, delete image if there was one */
    try {
      await firebase.deleteFile(`suppliers/${supplier.id}`)
    } catch (e) {
      console.log('no file existing')
    }
  } else {
    /* we have an icon path, check if it is local */
    if ((supplier.icon_path as unknown as File)?.name) {
      /* it has name -> is File Object */

      const file = new File([supplier.icon_path], supplier.id, {
        type: (supplier.icon_path as unknown as File).type
      })
      supplier.icon_path = await uploadFile(`suppliers`, file, firebase)
    }
  }
  await firebase.update(`suppliers/${supplier.id}`, {
    preferred_types: supplier.preferred_types || null,
    name: supplier.name || null,
    created_at: supplier.created_at || null,
    icon_path: supplier.icon_path || null,
    min_order_value: supplier.min_order_value || null,
    is_test_user: supplier.is_test_user || null,
    phone: supplier.phone || null,
    locked: supplier.locked || null,
    locked_at: supplier.locked
      ? supplier.locked_at || moment().format('x')
      : null,
    faxProvider: supplier.faxProvider || null
  })

  return
}
