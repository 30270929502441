import { action, on, payload, reducer } from 'ts-action'
import { Merchant } from '@hierfoods/interfaces'
/* Interfaces */

export interface MerchantState {
  merchant?: Merchant | null
  merchants?: Merchant[]
}

/* Redux */
export const setMerchant = action('setMerchant', payload<Merchant | null>())
export const setMerchants = action('setMerchants', payload<Merchant[]>())

const initialState: MerchantState = {}

export const merchantReducer = reducer(
  initialState,
  on(setMerchant, (state, { payload }) => ({
    ...state,
    merchant: payload
  })),
  on(setMerchants, (state, { payload }) => ({
    ...state,
    merchants: payload
  }))
)
