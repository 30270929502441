import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme
} from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import MenuIcon from '@material-ui/icons/Menu'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import clsx from 'clsx'
import React, { ReactChild } from 'react'
import { useTranslation } from 'react-i18next'
import { useFirebase } from 'react-redux-firebase'
import { NavLink, useLocation } from 'react-router-dom'

const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    hide: {
      display: 'none'
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0
    },
    drawerPaper: {
      width: drawerWidth
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end'
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      marginLeft: -drawerWidth
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 0
    },

    title: {
      flexGrow: 1
    },
    link: {
      color: theme.palette.text.primary,
      textDecoration: 'none'
    },
    activeLink: {
      fontWeight: 'bold'
    }
  })
)

export default function CustomDrawer({ children }: { children: ReactChild }) {
  const { t } = useTranslation()
  const location = useLocation()

  const classes = useStyles()
  const theme = useTheme()
  const firebase = useFirebase()

  const [open, setOpen] = React.useState(false)
  const options = [t('buttons.logout')]

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const submenuOpen = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const logout = () => {
    firebase.logout()
    closeMenu()
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }
  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <div className={classes.root}>
      <CssBaseline />

      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {process.env.REACT_APP_STAGING === 'true'
              ? 'HIER ADMIN STAGING'
              : 'HIER ADMIN'}
          </Typography>
          <IconButton
            color="inherit"
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            edge="end"
            onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={submenuOpen}
            onClose={closeMenu}
            PaperProps={{
              style: {
                maxHeight: 90,
                width: '15ch'
              }
            }}>
            {options.map(option => (
              <MenuItem key={option} onClick={logout}>
                {option}
              </MenuItem>
            ))}
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper
        }}>
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <nav>
          <List>
            {[
              'orders',
              'archivedorders',
              'billing',
              'merchants',
              'merchantsupplier',
              'suppliers',
              'supplier_categories',
              'products',
              'prices',
              'favorites',
              'whatsapp',
              'devices',
              'supplierLogins',
              'import'
            ].map(link => (
              <NavLink to={`/${link}`} className={classes.link} key={link}>
                <ListItem selected={location.pathname === `/${link}`} button>
                  <ListItemText
                    primary={t(`drawer.items.${link}`)}
                    color={'inherit'}
                  />
                </ListItem>
              </NavLink>
            ))}
          </List>
        </nav>
      </Drawer>

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open
        })}>
        <div className={classes.drawerHeader} />
        {children}
      </main>
    </div>
  )
}
