import { Supplier } from '@hierfoods/interfaces'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { setSupplier } from '@redux/supplier'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useFirebaseConnect } from 'react-redux-firebase'
import { selectSupplier, selectSupplierList } from '@selectors/supplier'
import { insertUrlParam, useQuery } from '../services/helpers'

const SupplierDropdown = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  useFirebaseConnect(['suppliers'])
  const supplier = useSelector(selectSupplier)
  const suppliers = useSelector(selectSupplierList)

  const query = useQuery()
  const querySupplier = query.get('supplierID')
  React.useEffect(() => {
    // First render, check if we have a url query set
    if (suppliers) {
      if (querySupplier && !supplier)
        dispatch(setSupplier(suppliers.find(s => s.id === querySupplier)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suppliers, supplier])

  React.useEffect(() => {
    // When supplier changes, change url search
    if (supplier) insertUrlParam('supplierID', supplier.id)
  }, [supplier])

  // If we have a query and no supplier yet, show Loading
  if (querySupplier && !supplier) return <div>Loading Supplier</div>

  return (
    <Autocomplete
      options={suppliers}
      getOptionLabel={supplier => `${supplier.name} (${supplier.id})`}
      style={{ width: 250 }}
      renderInput={params => <TextField {...params} label={t('supplier')} />}
      onChange={(event: any, supplier: Supplier | null) => {
        dispatch(setSupplier(supplier))
      }}
      value={supplier}
      getOptionSelected={(option, value) => option.id === value.id}
    />
  )
}

export default SupplierDropdown
