import mapValues from 'lodash/mapValues'
import { createDeepEqualSelector } from './util'

const emptyObject = {}

export const selectCategories = createDeepEqualSelector(
  (s: any) => s.firebase.data?.categories,
  (categories) => (categories ? mapValues(categories, 'title') : emptyObject)
)
export const selectCategoriesForAutocomplete = createDeepEqualSelector(
  selectCategories,
  (categoriesObject) =>
    Object.keys(categoriesObject).map((key) => ({
      title: categoriesObject[key],
      key
    }))
)
