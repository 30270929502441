import { Supplier, Product } from '@hierfoods/interfaces'
import { ExtendedFirebaseInstance } from 'react-redux-firebase'
import Papa from 'papaparse'

import {
  FileMapping,
  CSVProductDiff,
  DBFields,
  ProcessedRecord,
  CSV
} from './types'
import { fieldsConfiguration, defaultTemplates } from './configuration'

export async function importCSV(
  mapping: FileMapping,
  importerFile: File
): Promise<CSV> {
  console.log('using encdoing', mapping.encoding)
  return new Promise(function (resolve, reject) {
    const reader = new FileReader()
    reader.onload = e => {
      const text = e.target.result as string
      const parseOptions = {
        header: true,
        encoding: mapping.encoding,
        skipEmptyLines: true,
        //encoding: 'ISO-8859-1',
        transform: (v?: string) => {
          if (typeof v === 'string') return v.trim()
          return v
        }
      }
      const json = Papa.parse(text, parseOptions)
      resolve(json)
    }
    reader.readAsText(importerFile, mapping.encoding)
  })
}

export async function validateInputSheet(mapping: FileMapping, csv: CSV) {
  const rows = csv.data as Record<string, string>[]
  const stratergy = getStrategy(mapping)
  if (csv.errors.length > 0) {
    console.error('csv errors', csv.errors)
  }
  if (stratergy.validateSheet) {
    return stratergy.validateSheet(rows)
  }
  return true
}

export const revGet = (obj: Record<any, any>, value: string) => {
  const entry = Object.entries(obj).find(item => item[1] === value)
  return entry?.[0]
}

const hasKeys = (obj: object, keys: string[]) => {
  return keys.every(key => {
    return obj.hasOwnProperty(key) && obj[key]
  })
}

export function getStrategy(mapping: FileMapping) {
  const stratergy = defaultTemplates.find(
    el => el.name === mapping.preProcessingStratergy
  )
  if (!stratergy) throw new Error('No stratergy')
  return stratergy
}

export async function mapCSVRecords(
  mapping: FileMapping,
  records: any[]
): Promise<ProcessedRecord[]> {
  const stratergy = getStrategy(mapping)
  const fieldsConfiguration = stratergy.fieldsConfiguration
  const mappedRecords = records.map(inputRecord => {
    return Object.values(DBFields).reduce((state, dbFieldName) => {
      const specifiedFieldName = mapping.fields[dbFieldName]
      const fieldConfiguration = fieldsConfiguration[dbFieldName]
      const fieldValue = inputRecord[specifiedFieldName]
      return {
        ...state,
        ...(fieldValue
          ? {
              [dbFieldName]:
                fieldConfiguration && fieldConfiguration.reader
                  ? fieldConfiguration.reader(fieldValue)
                  : fieldValue
            }
          : {})
      }
    }, {}) as Product
  })
  const processedRecords = records.map((inputRecord, i) => {
    const row = mappedRecords[i]
    const output = stratergy.preProcessors.row
      ? (stratergy.preProcessors.row(row, i, mappedRecords) as Product)
      : row
    return {
      original: inputRecord,
      mapped: output
    }
  })

  console.log(stratergy.fieldsConfiguration)
  const requiredFields = Object.keys(fieldsConfiguration).filter(fieldId => {
    const conf = fieldsConfiguration[fieldId]
    return conf.required
  })
  const validatedRecords = processedRecords.filter(({ mapped, original }) => {
    return mapped && hasKeys(mapped, requiredFields)
  })
  console.log(
    'requiredFields',
    requiredFields,
    processedRecords.length,
    validatedRecords.length
  )
  return validatedRecords
}

export function lookupRecordBySupplierId(
  productList: Product[],
  supplier_product_id: string | undefined,
  hier_id: string | undefined
) {
  if (hier_id) {
    return productList.find(r => r.id === hier_id)
  }
  if (supplier_product_id) {
    return productList.find(r => r.supplier_product_id === supplier_product_id)
  }
}

export function computeDeletedRecords(
  currentState: Product[],
  nextState: ProcessedRecord[]
) {
  const presentProducts = currentState.filter(e => !e.soft_deleted)
  const deleted = presentProducts.filter(
    record =>
      !lookupRecordBySupplierId(
        nextState.map(el => el.mapped),
        record.supplier_product_id,
        undefined
      )
  )
  return deleted
}

export function computeCreatedRecords(
  currentState: any[],
  nextState: ProcessedRecord[]
) {
  return nextState.filter(
    ({ mapped: record }) =>
      !lookupRecordBySupplierId(
        currentState,
        record.supplier_product_id,
        record.id
      )
  )
}

export function computeUpdatedRecords(
  currentState: any[],
  nextState: ProcessedRecord[]
) {
  return nextState.filter(({ mapped: record }) =>
    lookupRecordBySupplierId(
      currentState,
      record.supplier_product_id,
      record.id
    )
  )
}

export async function computeDiff(
  currentState: Product[],
  nextState: Record<string, string>[],
  fileMapping: FileMapping
): Promise<CSVProductDiff> {
  // pre process entire sheet
  const stratergy = getStrategy(fileMapping)
  const preProcessedRecords = await (async function () {
    if (!stratergy.preProcessors.sheet) {
      return nextState
    }
    return stratergy.preProcessors.sheet(nextState, fileMapping)
  })()
  const mappedNextStateWithOriginal = await mapCSVRecords(
    fileMapping,
    preProcessedRecords
  )
  console.debug('currentState', {
    currentState,
    nextState,
    preProcessedRecords,
    mappedNextStateWithOriginal
  })
  return {
    deleted: fileMapping.deleteUnmatched
      ? computeDeletedRecords(currentState, mappedNextStateWithOriginal)
      : [],
    created: computeCreatedRecords(currentState, mappedNextStateWithOriginal),
    updated: computeUpdatedRecords(currentState, mappedNextStateWithOriginal)
  }
}

export const ensureExistenceOfConnections = async (
  firebase: ExtendedFirebaseInstance,
  product: Product,
  mapping: FileMapping,
  intermittentState: Record<any, any>
) => {
  const promises = Object.keys(mapping.connections).map(async fieldId => {
    const fieldConfiguration = fieldsConfiguration[fieldId]
    const connectionInfo = mapping.connections[fieldId as DBFields]
    const fbRefName =
      connectionInfo.refName || fieldConfiguration.connectionName
    const connection = connectionInfo.items

    if (!connectionInfo || !connection) return
    const fieldValue = product[fieldId]

    if (fieldValue) {
      const stateFieldAndValueKey = fieldId + '/' + fieldValue
      const getCurrentConnectionRecords = () => {
        return [
          ...connectionInfo.items,
          ...(intermittentState[fbRefName] || [])
        ]
      }
      const exists = getCurrentConnectionRecords().find(
        item =>
          item[fieldConfiguration.connectionLookupField || 'title'] ===
          fieldValue
      )

      let fieldValueId = exists
        ? exists.id || exists.key
        : await (async () => {
            if (intermittentState[stateFieldAndValueKey]) {
              return intermittentState[stateFieldAndValueKey]
            }
            if (!fieldConfiguration) return
            if (!fbRefName) return

            const partialValue = {
              [fieldConfiguration.connectionLookupField]: fieldValue
            }

            const value = {
              ...partialValue,
              ...(connectionInfo.propGenerator
                ? connectionInfo.propGenerator(
                    partialValue,
                    getCurrentConnectionRecords()
                  ) || {}
                : {})
            }

            const updateOp = firebase.database().ref(fbRefName).push(value)
            intermittentState[stateFieldAndValueKey] = updateOp.key
            intermittentState[fbRefName] = [
              ...(intermittentState[fbRefName] || []),
              { ...value, id: updateOp.key }
            ]
            return updateOp.key
          })()
      product[fieldId] = fieldValueId
    } else {
      product[fieldId] = null
    }
  })
  await Promise.all(promises)
  return product
}

// uses update so as to not overwrite fields
export const updateProduct = async (
  firebase: ExtendedFirebaseInstance,
  supplier: Supplier,
  mapping: FileMapping,
  task: IFirebaseTask,
  intermittentState: Record<any, any>,
  products: Product[]
) => {
  const db = firebase.database()
  const strategy = getStrategy(mapping)
  const { type } = task
  console.log('task', task.payload, supplier)
  if (task.type === 'delete') {
    const { payload: product } = task
    const exisingProduct = lookupRecordBySupplierId(
      products,
      product.supplier_product_id,
      product.id
    )
    const deletedProduct = {
      ...exisingProduct,
      ...product,
      id: exisingProduct.id,
      soft_deleted: true
    }
    console.log('DELETED', deletedProduct)
    await db
      .ref(`products/${supplier.id}/${exisingProduct.id}`)
      .update(deletedProduct)
    if (strategy.databaseHook)
      await strategy.databaseHook(
        firebase,
        mapping,
        product,
        product as any,
        'delete'
      )
  } else {
    const {
      payload: { original, mapped: rawProduct }
    } = task

    const product = await ensureExistenceOfConnections(
      firebase,
      rawProduct,
      mapping,
      intermittentState
    )

    if (type === 'update') {
      const exisingProduct = lookupRecordBySupplierId(
        products,
        product.supplier_product_id,
        product.id
      )
      if (!exisingProduct) throw new Error('product not found')
      console.log(exisingProduct, product)
      const updatedProduct = {
        ...exisingProduct,
        ...(strategy.onlyUpdatePrices
          ? {
              ...(Number.isFinite(product.bulk_price)
                ? { bulk_price: product.bulk_price }
                : {})
            }
          : {
              // update all fields
              ...product
            }),
        id: exisingProduct.id,
        soft_deleted: null as true | null // should be either true or null
      }
      console.log('UPDATE', updatedProduct)
      await db
        .ref(`products/${supplier.id}/${exisingProduct.id}`)
        .update(updatedProduct)
      if (strategy.databaseHook)
        await strategy.databaseHook(
          firebase,
          mapping,
          updatedProduct,
          original,
          'update'
        )
    } else if (type === 'create') {
      console.log('CREATE', product)
      const p = db.ref(`products/${supplier.id}`).push(product)
      if (strategy.databaseHook)
        await strategy.databaseHook(
          firebase,
          mapping,
          { ...product, id: p.key },
          original,
          'create'
        )
    }
  }
}
export type IFirebaseTask =
  | { type: 'delete'; payload: Product }
  | {
      type: 'update' | 'create'
      payload: ProcessedRecord
      id?: string
    }

export async function updateRecords(
  firebase: ExtendedFirebaseInstance,
  supplier: Supplier,
  mapping: FileMapping,
  diff: CSVProductDiff,
  currentProducts: Product[],
  onProgress: Function
) {
  const tasks: IFirebaseTask[] = [
    ...diff.deleted.map(p => ({
      type: 'delete' as const,
      payload: p
    })),
    ...diff.updated.map(p => ({
      type: 'update' as const,
      payload: p
    })),
    ...diff.created.map(p => ({
      type: 'create' as const,
      payload: p
    }))
  ]
  const results = []
  const intermittentState = {}
  for await (const task of tasks) {
    onProgress((tasks.indexOf(task) * 100) / tasks.length)
    results.push(
      await updateProduct(
        firebase,
        supplier,
        mapping,
        task as IFirebaseTask,
        intermittentState,
        currentProducts
      )
    )
  }
  return results
}
