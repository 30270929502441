import { Order } from '@hierfoods/interfaces'
import {
  Box,
  createStyles,
  LinearProgress,
  makeStyles,
  Theme
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import converter from 'json-2-csv'
import moment from 'moment'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFirebase } from 'react-redux-firebase'
import MerchantListDropdown from './merchantListDropdown'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    loader: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2)
      }
    }
  })
)

interface BillingExportOrder extends Order {
  merchant_name: string
}

const Billing = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  const firebase = useFirebase()
  const [loading, setLoading] = useState(false)
  const [exportMerchantsList, setBillingMerchantsList] = useState<string[]>([])
  const [exportFromDate, setExportFromDate] = useState<string | null>()
  const [exportToDate, setExportToDate] = useState<string | null>()

  const createCSVFromJsonAndDownload = (data, filename) => {
    return new Promise((resolve, reject) => {
      converter.json2csv(
        data,
        async (err, csv) => {
          if (err) {
            console.error(err)
            reject(err)
          } else {
            // Create a Link element with our CSV as download and click it
            const element = document.createElement('a')
            const file = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
            element.href = URL.createObjectURL(file)
            element.download = `${filename}.csv`
            document.body.appendChild(element)
            element.click()
            element.remove()
            resolve(true)
          }
        },
        {
          emptyFieldValue: ''
        }
      )
    })
  }
  const startBillingExport = async () => {
    const billingExport = (firebase as any)
      .functions()
      .httpsCallable('api-generateBillingExport')
    try {
      setLoading(true)
      const result = await billingExport({
        merchant_ids: exportMerchantsList,
        start: moment(exportFromDate).format('DD.MM.YYYY'),
        end: moment(exportToDate).format('DD.MM.YYYY')
      })

      if (result.data) {
        const allOrders = result.data as BillingExportOrder[]
        const merchantMap: {
          [merchantID: string]: BillingExportOrder[]
        } = {}
        allOrders.forEach(order => {
          if (!merchantMap[order.merchant_id]) {
            merchantMap[order.merchant_id] = []
          }
          merchantMap[order.merchant_id].push(order)
        })
        for await (const orderSet of Object.values(merchantMap)) {
          await createCSVFromJsonAndDownload(
            orderSet,
            `${orderSet[0].merchant_name}_${moment(exportFromDate).format(
              'DDMMYYYY'
            )}-${moment(exportToDate).format('DDMMYYYY')}`
          )
        }
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoading(false)
    }
  }
  return (
    <div className={classes.root}>
      <Box minWidth="400px" maxWidth="80vw">
        <MerchantListDropdown
          selectedMerchants={exportMerchantsList}
          onChange={e => {
            setBillingMerchantsList(e.target.value)
          }}
        />

        <Box marginY="20px" display="flex" justifyContent="space-between">
          <TextField
            id="fromDate"
            value={exportFromDate}
            onChange={e => setExportFromDate(e.target.value)}
            label={t('orderList.exportCsvPopup.fromDate')}
            type="date"
            InputLabelProps={{
              shrink: true
            }}
          />
          <TextField
            id="toDate"
            value={exportToDate}
            onChange={e => setExportToDate(e.target.value)}
            label={t('orderList.exportCsvPopup.toDate')}
            type="date"
            InputLabelProps={{
              shrink: true
            }}
          />
        </Box>
        {loading && (
          <div className={classes.loader}>
            <LinearProgress />
          </div>
        )}
        <Button color="primary" onClick={startBillingExport}>
          {t('buttons.download')}
        </Button>
      </Box>
    </div>
  )
}

export default Billing
