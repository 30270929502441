/* eslint-disable no-useless-concat */
/* eslint-disable no-useless-escape */
import {
  DBFieldsConfiguration,
  CSVTemplate,
  DBFields,
  FileMapping
} from './types'

export const parseEuropeanNumber = (value: string) =>
  parseFloat(value.replace(/\./g, '').replace(',', '.'))

export const fieldsConfiguration: DBFieldsConfiguration = {
  //  [DBFields.PRICE]: {
  //    reader: (value) => parseFloat(value)
  //  },
  [DBFields.TITLE]: {
    required: true
  },
  [DBFields.BULK_PRICE]: {
    reader: (value) => parseFloat(value),
    required: true
  },
  [DBFields.BULK_AMOUNT]: {
    reader: (value) => parseFloat(value),
    required: true
  },
  [DBFields.PRODUCT_TYPE]: {
    connectionName: 'product_types',
    connectionLookupField: 'title',
    required: true
  },
  [DBFields.CATEGORY]: {
    connectionName: 'categories',
    connectionLookupField: 'title'
  },
  [DBFields.SUPPLIER_CATEGORY]: {
    connectionName: 'supplier_categories',
    connectionLookupField: 'title'
  },
  [DBFields.BULK_UNIT]: {
    connectionName: 'bulk_units',
    connectionLookupField: 'title'
  },
  [DBFields.SINGLE_UNIT]: {
    connectionName: 'single_units',
    connectionLookupField: 'title'
  },
  [DBFields.VAT]: { connectionName: 'vat', connectionLookupField: 'value' }
}

export const WeillerTemplate: CSVTemplate = {
  name: 'Weiller',
  onlyUpdatePrices: true,
  fieldsMapping: {
    [DBFields.NUMBER]: 'Artnr.',
    [DBFields.PRODUCT_TYPE]: 'Best.menge',
    [DBFields.TITLE]: 'Artikel',
    [DBFields.BULK_UNIT]: 'Verp.',
    //  [DBFields.PRICE]: 'Preis',
    [DBFields.BULK_PRICE]: 'KO-Preis €',
    [DBFields.BULK_AMOUNT]: 'Inh.'
  },
  fieldsConfiguration: {
    ...fieldsConfiguration,
    [DBFields.BULK_PRICE]: {
      reader: parseEuropeanNumber,
      required: true
    },
    [DBFields.BULK_AMOUNT]: {
      reader: parseEuropeanNumber,
      required: true
    }
  },
  preProcessors: {
    row: (row, index, list) => {
      if (row[DBFields.PRODUCT_TYPE]) {
        return row
      } else {
        const previousRow = list
          .slice(0, index)
          .reverse()
          .find((el) => {
            return el[DBFields.PRODUCT_TYPE]
          })
        return {
          ...row,
          [DBFields.PRODUCT_TYPE]: previousRow
            ? previousRow[DBFields.PRODUCT_TYPE]
            : undefined
        }
      }
    }
  }
}

function escapeRegex(string: string) {
  return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
}

export function compileProductTypeRegexes(mapping: FileMapping) {
  const list: { regex: RegExp; product_type: string }[] = []
  for (let product_type_entry of mapping.connections.product_type_id.items) {
    const product_type_title = product_type_entry.title
    const regexString =
      `(${escapeRegex(product_type_title as string)})` + `(\\s|,){1}`
    //    console.log(regexString)
    if (product_type_title && typeof product_type_title == 'string') {
      let regex = new RegExp(regexString)
      list.push({ regex, product_type: product_type_title })
    }
  }
  return list
}

export function formatTitle(
  title: string,
  mapping: FileMapping,
  regexes: { regex: RegExp; product_type: string }[]
): { title: string; product_type?: string } {
  let product_type: string | undefined
  for (let regexEntry of regexes) {
    const match = title.match(regexEntry.regex)
    if (match) {
      title = title.replace(regexEntry.regex, '').trim()
      product_type = regexEntry.product_type
      break
    }
  }
  return { title, product_type }
}

export interface FreyMultipleMerchantsSheetRow {
  Kundennummer: string
  Lieferdatum_von: string
  Lieferdatum_bis: string
  Artikelnummer: string
  GTIN: string
  Artikelgruppe: string
  Artikelbezeichnung: string
  Liefereinheit: string
  Kolli_Inhalt: string
  Einzeleinheit: string
  Gewicht_g: string
  Volumen_ml: string
  VK_Frey_Kolli: string
  VK_Frey_ME: string
  LVP: string
  Mehrwertsteuersatz: string
  Bild_URL: string
  Herkunftsland: string
}
export interface FreyMultipleMerchantsSheetProduct {
  prices: { [merchantId: string]: number }
  selling_prices: { [merchantId: string]: number }
}
export const FreyMultipleMerchantsTemplate: CSVTemplate = {
  name: 'Frey Multiple Merchants',
  encoding: 'windows-1252',
  fieldsMapping: {
    [DBFields.NUMBER]: 'Artikelnummer',
    [DBFields.TITLE]: 'Artikelbezeichnung',
    [DBFields.EAN]: 'GTIN',
    [DBFields.VAT]: 'Mehrwertsteuersatz',
    [DBFields.SINGLE_UNIT]: 'Einzeleinheit',
    [DBFields.BULK_AMOUNT]: 'Kolli_Inhalt',
    [DBFields.CATEGORY]: 'Artikelgruppe',
    [DBFields.SUPPLIER_CATEGORY]: 'Artikelgruppe',
    // added by preprocessor
    [DBFields.WEIGHT]: 'Gewicht in g',
    [DBFields.PRODUCT_TYPE]: 'ProductType'
  },
  //
  fieldsConfiguration: {
    ...fieldsConfiguration,
    [DBFields.BULK_PRICE]: {
      reader: (value) => parseFloat(value),
      required: false
    },
    [DBFields.BULK_AMOUNT]: {
      reader: (value) => parseFloat(value),
      required: false
    },
    [DBFields.PRODUCT_TYPE]: {
      connectionName: 'product_types',
      connectionLookupField: 'title',
      required: false
    }
  },
  validateSheet: async (rows) => {
    const unitsInSheet = [...new Set(rows.map((r) => r.Einzeleinheit))]
    const necessaryUnits = ['St', 'Kg']

    if (
      necessaryUnits.every((u) => unitsInSheet.indexOf(u) > 1) ||
      unitsInSheet.length > 2
    ) {
      throw new Error(
        `Only, St/Kg allowed. FOUND following units ${unitsInSheet.join(', ')}`
      )
    }
    return true
  },
  disableReMapping: true,
  preProcessors: {
    sheet: async (inputRows, mapping) => {
      console.log('mapping', mapping)

      const CustomerIdToMerchantIdDictionary = mapping.supplierMerchantsRelationships
        .filter((el) => el.customerID)
        .reduce<{ [customerId: string]: string }>((state, el) => {
          if (!el.customerID) return state
          return { ...state, [el.customerID]: el.merchantId }
        }, {})

      const rows = inputRows
      console.log('input/output', { inputRows, rows })
      // reduce and group rows by article number
      const productTypeRegexes = compileProductTypeRegexes(mapping)

      const products = Object.values(
        rows.reduce<{
          [articleNumber: string]: FreyMultipleMerchantsSheetProduct
        }>((state, item) => {
          const lvp = parseFloat(item.LVP)
          const merchantId = CustomerIdToMerchantIdDictionary[item.Kundennummer]
          const vatRate = parseFloat(item.Mehrwertsteuersatz)

          const packageContent = item.Kolli_Inhalt
          const packageContentNumeric = parseFloat(packageContent)
          const bulkPrice = parseFloat(item.VK_Frey_Kolli)

          let { title, product_type } = formatTitle(
            item.Artikelbezeichnung,
            mapping,
            productTypeRegexes
          )
          title = item.Herkunftsland
            ? `${title}` +
              ' ' +
              String.fromCharCode(0x2691) +
              String.fromCharCode(0x202f) +
              item.Herkunftsland.trim()
            : title
          const product = {
            Artikelnummer: item.Artikelnummer,
            ProductType: product_type,
            ...(title ? { Artikelbezeichnung: title } : {}),
            ...(item.GTIN ? { GTIN: item.GTIN } : {}),
            ...(item.Artikelgruppe
              ? { Artikelgruppe: item.Artikelgruppe }
              : {}),
            ...(item.Einzeleinheit === 'St' ? { Einzeleinheit: 'Stück' } : {}),
            ...(item.Einzeleinheit === 'St'
              ? { Kolli_Inhalt: item.Kolli_Inhalt }
              : {}),
            ...(item.Einzeleinheit === 'Kg' &&
            Number.isFinite(packageContentNumeric)
              ? { 'Gewicht in g': Math.round(packageContentNumeric * 1000) }
              : {}),
            ...(Number.isFinite(vatRate)
              ? {
                  // parseFloat((parseFloat(4.10)*100).toFixed(2)) == 410
                  Mehrwertsteuersatz: parseFloat((1 + vatRate).toFixed(3))
                }
              : {}),
            prices: {
              ...(state[item.Artikelnummer]?.prices
                ? state[item.Artikelnummer].prices
                : {}),
              ...(merchantId && Number.isFinite(bulkPrice)
                ? {
                    [merchantId]: Math.round(bulkPrice * 100)
                  }
                : {})
            },
            selling_prices: {
              ...(state[item.Artikelnummer]?.selling_prices
                ? state[item.Artikelnummer].selling_prices
                : {}),
              ...(merchantId && Number.isFinite(lvp) && lvp > 0
                ? {
                    [merchantId]: Math.round(lvp * 100)
                  }
                : {})
            },
            EK: 0,
            VK: 0
          }
          return {
            ...state,
            [item.Artikelnummer]: product
          }
        }, {})
      )
      console.log('products', products)
      return products
    }
  },
  databaseHook: async (fb, mapping, record, originalRow, action) => {
    const csvRow: FreyMultipleMerchantsSheetProduct = (originalRow as unknown) as FreyMultipleMerchantsSheetProduct
    // do nothing for now for delete product since they are soft_deleted anyways
    if (['create', 'update'].indexOf(action) > -1) {
      const promises = Object.keys(csvRow.prices || {}).map((merchantId) => {
        const bulkPrice = originalRow.prices[merchantId]
        const ref = `/prices/${mapping.supplier.id}/merchants/${merchantId}/${record.id}`

        return fb.ref(ref).update({
          bulk_price: bulkPrice,
          selling_price: null
        })
      })
      await Promise.all(promises)
    }
  }
}

export const defaultTemplates: CSVTemplate[] = [
  WeillerTemplate,
  FreyMultipleMerchantsTemplate
]
