import { MerchantState } from '@redux/merchant'
import { createSelector } from 'reselect'

const merchantStateSelector = (state: { merchant: MerchantState }) =>
  state.merchant

export const selectMerchant = createSelector(
  merchantStateSelector,
  (merchantState) => merchantState.merchant
)

export const selectMerchantList = createSelector(
  (s: any) => s?.firebase?.data?.merchants,
  (merchants) =>
    merchants
      ? Object.keys(merchants)
          .map((key) => {
            let merchant = merchants[key]
            merchant.id = key
            return merchant
          })
          .sort((sup1, sup2) => (sup1.name > sup2.name ? 1 : -1))
      : []
)
