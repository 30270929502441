import { supplierReducer } from '@redux/supplier'
import { merchantReducer } from '@redux/merchant'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/functions'
import 'firebase/storage'
import React from 'react'
import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'
import {
  firebaseReducer,
  ReactReduxFirebaseProvider
} from 'react-redux-firebase'
import { combineReducers, createStore } from 'redux'
import App from './App'
import i18n from './translations/i18n'
import { persistStore, persistReducer } from 'redux-persist'
import localStorage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native
import hardSet from 'redux-persist/es/stateReconciler/hardSet'
import { PersistGate } from 'redux-persist/integration/react'

const fbConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SERNDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'merchants',
  enableClaims: true
}

// Initialize firebase instance
firebase.initializeApp(fbConfig)

if (process.env.REACT_APP_EMULATOR) {
  firebase.auth().useEmulator('http://localhost:9099')
  firebase.database().useEmulator('localhost', 9000)
  firebase.functions().useEmulator('localhost', 5001)
  console.log('Firebase is pointing at local emulators')
}

// Add firebase to reducers
const rootReducer = combineReducers({
  firebase: persistReducer(
    {
      key: 'firebaseState',
      storage: localStorage,
      stateReconciler: hardSet,
      whitelist: ['auth']
    },
    firebaseReducer
  ),
  supplier: supplierReducer,
  merchant: merchantReducer
})

const initialState = {}
const store = createStore(
  rootReducer,
  initialState,
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__()
)
const persistor = persistStore(store)

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </ReactReduxFirebaseProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)
