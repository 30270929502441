import { createSelector } from 'reselect'

const selectDevices = (s: { firebase: { data: any } }) =>
  s.firebase.data.devices

export const selectDevicesMapped = createSelector(selectDevices, (devices) => {
  const out: any[] = []
  if (!devices) return out
  Object.entries(devices).forEach(([merchantID, merchantDevices]) => {
    Object.values(merchantDevices).forEach((merchantDevice) =>
      out.push({ ...merchantDevice, merchantID })
    )
  })
  return out.filter((d) => !!d.revisionId)
})
